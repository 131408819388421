
export enum DeliveryStatus {
    IN_STOCK = 'IN_STOCK',
    NO_STOCK = 'NO_STOCK',
    VENDOR_STOCK = 'VENDOR_STOCK'
}

export enum StocksColor {
    GREEN = 'GREEN',
    GREY = 'GREY',
    ORANGE = 'ORANGE',
    RED = 'RED'
}

export enum ShopMode {
    MISC = 'MISC',
    MOUNTEDWHEELS = 'MOUNTEDWHEELS',
    RIMCONFIGURATOR = 'RIMCONFIGURATOR',
    RIMS = 'RIMS',
    RIMS_AND_TYRES = 'RIMS_AND_TYRES',
    TYRES = 'TYRES'
}

export enum SortName {
    BRAND = 'BRAND',
    INITIAL = 'INITIAL',
    NOISE_EMISSION = 'NOISE_EMISSION',
    OWN_STOCK_FIRST_THEN_PRICE = 'OWN_STOCK_FIRST_THEN_PRICE',
    PRICE = 'PRICE',
    QUANTITY = 'QUANTITY',
    RECOMMENDED = 'RECOMMENDED',
    ROLLING_RESISTANCE = 'ROLLING_RESISTANCE',
    WET_GRIP = 'WET_GRIP'
}


export enum TyreSearchFilterKey {
    BRAND = 'BRAND',
    DIAMETER = 'DIAMETER',
    DOT = 'DOT',
    EXTRA_LOAD = 'EXTRA_LOAD',
    IMMEDIATE_DELIVERY = 'IMMEDIATE_DELIVERY',
    LOAD_INDEX = 'LOAD_INDEX',
    OE_MARK = 'OE_MARK',
    OWN_STOCK_AVAILABLE = 'OWN_STOCK_AVAILABLE',
    PRICE = 'PRICE',
    QUALITY = 'QUALITY',
    RFT = 'RFT',
    RFT_INTERNAL = 'RFT_INTERNAL',
    SEAL = 'SEAL',
    SEASON = 'SEASON',
    SECTION = 'SECTION',
    SPEED_INDEX = 'SPEED_INDEX',
    VEHICLE_TYPE = 'VEHICLE_TYPE',
    WIDTH = 'WIDTH',
    RETAILER_DISPLAY_NAME = 'RETAILER_DISPLAY_NAME',
    MIN_QUANTITY = 'MIN_QUANTITY'
}

export enum Axle {
    BOTH = "BOTH",
    FRONT = "FRONT",
    REAR = "REAR",
    NONE = "NONE"
}

export enum DriveEnum {
    FRONT = "FRONT",
    REAR = "REAR",
    FOURWHEEL = "FOURWHEEL"
}

export enum FuelEnum {
    PETROL = "PETROL",
    ELECTRO = "ELECTRO",
    DIESEL = "DIESEL",
    GASMOTOR = "GASMOTOR",
    HYBRID = "HYBRID"
}

export enum TpmsRequiredEnum {
    NOT_REQUIRED = 'NOT_REQUIRED',
    OPTIONAL = 'OPTIONAL',
    REQUIRED = 'REQUIRED',
    UNKNOWN = 'UNKNOWN'
}

export enum OrderStatus {
    CANCELED = 'CANCELED',
    ERROR = 'ERROR',
    LOADED = 'LOADED',
    OPEN = 'OPEN',
    SENT = 'SENT',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT'
}

export enum PositionType {
    ARTICLE = 'ARTICLE',
    MOUNTED_WHEEL = 'MOUNTED_WHEEL',
    RIM = 'RIM',
    TYRE = 'TYRE',
    VOUCHER = 'VOUCHER'
}

export enum ItemType {
    ARTICLE = 'ARTICLE',
    MOUNTINGKIT = 'MOUNTINGKIT',
    RIM = 'RIM',
    TPMS = 'TPMS',
    NO_TPMS = 'NO_TPMS',
    TYRE = 'TYRE',
    VOUCHER = 'VOUCHER',
    WHEELASSEMBLY = 'WHEELASSEMBLY'
}

export enum EuLabelRollingResistanceColor {
   A = '#4BA359',
   B = '#A3C84B',
   C = '#E8D027',
   D = '#F5BA41',
   E = '#DE3529',
}
export enum EuLabelWetGripColor {
   A = '#2564AF',
   B = '#337EC4',
   C = '#5CA4D9',
   D = '#83BCE7',
   E = '#B3DFFB',
}
export enum EuLabelNoiseClassColor {
   A = '#606060',
   B = '#505050',
   C = '#404040',
}
