import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../../../components/Input/Input";
import { UrlSearchParamsHelper } from "../../../../helper/UrlSearchParamsHelper";
import { useMediaQuery } from "../../../../helper/useMediaQuery";
import {InputType} from '../../../../models/shared/Types';

interface RimSearchParams {
    width?: string;
    diameter?: string;
    rimOffset?: string;
    holeCircle?: string;
    holeCount?: string;
    hubBoreHole?: string;
    brand?: string;
    design?: string;
}

interface RimSearchParamsOptions {
    widths?: string[];
    diameters?: string[];
    rimOffsets?: string[];
    holeCircles?: string[];
    holeCounts?: string[];
    hubBoreHoles?: string[];
    brands?: string[];
    designs?: string[];
}

interface Props {
    isLoading: boolean;
    searchParams: RimSearchParams;
    searchParamsOptions: RimSearchParamsOptions;
    searchParamsCallback: any;
    isSmallScreen?: boolean;
}

function RimSearchParams(props: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [t] = useTranslation();
    const [search, setSearch] = useState<RimSearchParams>(props.searchParams);
    const isSmallScreen = useMediaQuery("(max-width:991.98px)");
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        setSearch(props.searchParams);
    }, [props.searchParams]);

    function onSearch() {
        UrlSearchParamsHelper.pushSearchToHistory(location, navigate, search);
        props.searchParamsCallback(search);
    }

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    function onChangeHandler(e: string[], criteria: string) {
        setSearch({ ...search, [criteria]: e.length > 0 ? e[0] : null });
    }

    function getInput(inputProps: { type?: InputType; labelKey: string; name: string; options: string[]; value: string[] }) {
        return (
            <div className="col-12 pb-3" key={inputProps.labelKey}>
                <Input
                    type={inputProps.type ?? "SINGLE_SELECT_DROPDOWN"}
                    label={t("RIMS.SEARCH.SEARCH_PARAMS." + inputProps.labelKey)}
                    name={inputProps.name}
                    options={inputProps.options}
                    value={inputProps.value}
                    onChange={(e) => onChangeHandler(e, inputProps.name)}
                />
            </div>
        );
    }

    const options = props.searchParamsOptions;
    const inputProperties: { type?: InputType; labelKey: string; name: string; options: string[]; value: string[] }[] = [
        { labelKey: "WIDTH", name: "width", options: options.widths, value: [search.width] },
        { labelKey: "DIAMETER", name: "diameter", options: options.diameters, value: [search.diameter] },
        { labelKey: "RIM_OFFSET", name: "rimOffset", options: options.rimOffsets, value: [search.rimOffset] },
        { labelKey: "HOLE_CIRCLE", name: "holeCircle", options: options.holeCircles, value: [search.holeCircle] },
        { labelKey: "HOLE_COUNT", name: "holeCount", options: options.holeCounts, value: [search.holeCount] },
        { labelKey: "HUB_BORE_HOLE", name: "hubBoreHole", options: options.hubBoreHoles, value: [search.hubBoreHole] },
        { labelKey: "DESIGN", name: "design", options: options.designs, value: [search.design] },
    ];

    return props.isLoading ? (
        <div className="rim-search-params loading" />
    ) : (
        <div className="rim-search-params">
            {isSmallScreen && (
                <div className="row click" onClick={toggleCollapsed}>
                    <div className="col fw-bold">{t("TYRES.SEARCH.CRITERIA")}</div>
                    <div className="col text-end">
                        <i className={"fas " + (collapsed ? "fa-chevron-up" : "fa-chevron-down")}></i>
                    </div>
                </div>
            )}
            {!(collapsed && isSmallScreen) && (
                <>
                    <div className={`row ${isSmallScreen ? "mt-3" : ""}`}>
                        {inputProperties.map((inputProperty) => getInput(inputProperty))}
                    </div>
                    <div>
                        <button className="btn btn-primary mt-3 w-100" onClick={() => onSearch()}>
                            {t("TYRES.SEARCH.APPLY")}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default RimSearchParams;
