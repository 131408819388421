import React, {ErrorInfo, PropsWithChildren} from "react";
import {LogLevel, logToServer} from "../redux-store/api/ServerLoggingApi";
import {t} from 'i18next';
import {Trans} from 'react-i18next';

export class ErrorBoundary extends React.Component<PropsWithChildren<any>, {hasError: boolean}> {


    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return  {
            hasError: true,
        }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logToServer({
            name: error.name,
            message: error.message,
            stack: error.stack,
            logLevel: LogLevel.WARN,
            customParameters: {
                source: 'GLOBAL_ERROR_HANDLER',
                errorInfo: errorInfo
            }
        });
    }

    render() {
        if (this.state.hasError) {
            return <div className={'p-3 my-5'}>
                <Trans t={t} i18nKey={'GLOBAL.ERROR_BOUNDARY'} components={[
                    <a href={"/"}>hier</a>
                ]}/>
            </div>
        }

        return this.props.children;
    }
}
