import React from "react";
import { useTranslation } from "react-i18next";
import {TyreEULabelDTO} from "../../models/tyres/TyreDTO";
import {
    EuLabelNoiseClassColor,
    EuLabelRollingResistanceColor,
    EuLabelWetGripColor
} from '../../models/shared/Enums';

interface Props {
    tyreEULabel: TyreEULabelDTO
}



export default function TyreEuLabel({tyreEULabel}: Props) {
    const [t] = useTranslation();

    return (<>
        <div className="eu-label-list mt-3 mb-1 gap-1">
            <img src={'/eu_tyre_label_rolling_resistance.svg'} width={20}/>
            <div className={'eu-label rolling-resistance'} style={{
                '--euLabelColor': EuLabelRollingResistanceColor[tyreEULabel.rollingResistance]
            } as React.CSSProperties}>
                {tyreEULabel.rollingResistance}
            </div>
            <img src={'/eu_tyre_label_wet_grip.svg'} width={20}/>
            <div className={'eu-label wet-grip'} style={{
                '--euLabelColor': EuLabelWetGripColor[tyreEULabel.wetGrip]
            } as React.CSSProperties}>
                {tyreEULabel.wetGrip}
            </div>
            <img src={'/eu_tyre_label_noise_emission.svg'} width={20}/>
            <div className={'eu-label noise-emission'} style={{
                '--euLabelColor': EuLabelNoiseClassColor[tyreEULabel.noiseClass]
            } as React.CSSProperties}>
                {tyreEULabel.noiseEmission + ' db'}
            </div>
        </div>
        <a
            href={`https://eprel.ec.europa.eu/qr/${tyreEULabel?.eprelRegistrationId}`}
            target="_blank"
            rel="noreferrer"
        >
            <i className="fas fa-file"/> {t("TYRES.DETAILS.PRODUCT_INFORMATION")}
        </a>
    </>);
}
