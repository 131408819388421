import Tooltip from "rc-tooltip";
import React from "react";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../helper/useMediaQuery";
import {InputHelper} from "./InputHelper";
import {Tooltip as ReactTooltip} from "react-tooltip";

interface Props {
    onChange: (checked: boolean) => void;
    name: string;
    label?: string;
    checked: boolean;
    children?: any;
}

export function InputCheckbox({ onChange, name, label, children, checked}: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)")

    return (
        <label className="custom-checkbox filter my-1" key={name}>
            <input
                type="checkbox"
                name={name}
                id={name}
                key={name}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
            />
            <span className="checkmark" />
            {label} {children}
            {InputHelper.additionalInformationAvailable[name] && (<>
                <i className="ms-1 fa fa-info-circle" onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                }} data-tooltip-id={name} />
                <ReactTooltip id={name} place={isSmallScreen ? 'bottom' : 'right'}>
                    <span>{t("FILTER.ADDITIONAL_INFORMATION." + name)}</span>
                </ReactTooltip>
            </>)}
        </label>
    );
}
