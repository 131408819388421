import {InputHelper} from '../Input/InputHelper';
import React, {useState} from 'react';
import {FilterInterface} from '../../models/shared/FilterInterface';
import {t} from 'i18next';
import {produce} from 'immer';
import {UrlFilterHelper} from '../../helper/UrlFilterHelper';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';

interface FilterSelectedProps {
    filters: FilterInterface[];
    filterSelectionCallback: (filters: FilterInterface[], disableReload?: boolean) => void;
}

export function FilterSelected({filters, filterSelectionCallback}: FilterSelectedProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const filterChanged = (values: string[], changedFilter: FilterInterface, apply?: boolean) => {
        const newFilters = produce(filters, (draft) => {
            draft.forEach((filter) => {
                if (filter.key === changedFilter.key) {
                    filter.selectedValues = values.length === 0 ? undefined : values;
                }
            });
        });

        if (apply) {
            filterSelectionCallback(newFilters, false);
            UrlFilterHelper.pushFiltersToHistory(location, navigate, newFilters);
        }
    };

    function clearFilter(filter: FilterInterface) {
        filterChanged([], filter, true);
    }

    function removeSelectedFilter(filter: FilterInterface, value: string) {
        const newValues = filter.selectedValues.filter((v) => v !== value)
        if(filter.key === 'MIN_QUANTITY' && newValues.length === 0) {
            newValues.push("0")
        }
        filterChanged(
            newValues,
            filter,
            true,
        );
    }

    return <div className="d-flex gap-2 mt-2">
        <>
            {filters?.map((f) => {
                if(f.type === "MULTI_SLIDER" && f?.selectedValues?.length === 2) {
                    return (<span className="badge bg-light text-dark fw-normal me-2 mb-2 p-2" key={'selection_' + f.key}>
                                    {InputHelper.filterLabel(f.selectedValues[0], f.key, t)}
                            {" - "}
                            {InputHelper.filterLabel(f.selectedValues[1], f.key, t)}
                            <i className="ms-1 fa fa-times cursor" onClick={() => clearFilter(f)}></i>
                                </span>);
                } else if(f.key === 'MIN_QUANTITY' && f.selectedValues?.[0] === '0') {
                    return null // skip this WCSB2C-498
                } else {
                    return f?.selectedValues?.map((value) => (<span
                            className="badge bg-light text-dark fw-normal p-2"
                            key={f.key + "_" + value}
                        >
                                    {InputHelper.filterLabel(value, f.key, t)}{" "}
                            <i
                                className="ms-1 fa fa-times cursor"
                                onClick={() => removeSelectedFilter(f, value)}
                            ></i>
                                </span>));
                }
            })}
        </>
    </div>
}
