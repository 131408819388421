import React, {
    useEffect,
    useState
} from "react";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    Link,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {Dispatch} from "redux";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import {FilterBar} from "../../../components/Filter/FilterBar";
import {scrollToTopFn} from "../../../components/ScrollToTop";
import {UrlFilterHelper} from "../../../helper/UrlFilterHelper";
import {UrlSearchParamsHelper} from "../../../helper/UrlSearchParamsHelper";
import {UrlSortHelper} from "../../../helper/UrlSortHelper";
import {useDidMount} from "../../../helper/useDidMount";
import {CarDetailDTO} from "../../../models/car/CarDetailDTO";
import {RimDetailDTO} from "../../../models/rims/RimDetailDTO";
import {
    ShopMode,
    SortName
} from "../../../models/shared/Enums";
import {
    initialSort,
    SearchSort
} from "../../../models/shared/SearchSort";
import {SortDirection} from "../../../models/shared/SortDirection";
import {MountedWheelCostsDTO} from "../../../models/tyres/MountedWheelDetailsDTO";
import {WishListDTO} from "../../../models/wishlist/WishlistDTO";
import {getCarDetails} from "../../../redux-store/api/CarApi";
import {getRimDetails} from "../../../redux-store/api/RimApi";
import {
    getMountedWheelCosts,
    getTyres,
    searchMixedTyres
} from "../../../redux-store/api/TyreApi";
import {getWishlist} from "../../../redux-store/api/WishlistApi";
import {
    TyreSearchAction,
    TyreSearchActionType,
    TyreSearchState,
} from "../../../redux-store/reducers/TyreSearchReducer";
import CompleteWheelBreadcrumb from "./CompleteWheelBreadcrumb/CompleteWheelBreadcrumb";
import TyreResults from "./TyreResults/TyreResults";
import TyreSearchParams from "./TyreSearchParams/TyreSearchParams";
import {FullScreenModeActionType} from "../../../redux-store/reducers/FullScreenModeReducer";
import ReactPaginate from "react-paginate";
import Filter from '../../../components/Filter/Filter';
import {useMediaQuery} from '../../../helper/useMediaQuery';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

interface TyreSearchParamsProps {
    matchcodeFront?: string;
    matchcodeRear?: string;
    carId?: string;
    rimFrontId?: string;
    rimRearId?: string;
    selectedAmount?: string;
    positionId?: string;
}

function TyreSearch() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params: TyreSearchParamsProps = useParams<keyof TyreSearchParamsProps>();
    const dispatch = useDispatch<Dispatch<TyreSearchAction>>();
    const searchState = useSelector<any, TyreSearchState>((state: any) => state.tyreSearch);
    const [isLoading, setIsLoading] = useState(true);
    const [breadcrumbLoading, setBreadcrumbLoading] = useState(false);
    const [carDetails, setCarDetails] = useState<CarDetailDTO>(null);
    const [rimDetails, setRimDetails] = useState<RimDetailDTO>(null);
    const [mountedWheelCosts, setMountedWheelCosts] = useState<MountedWheelCostsDTO>(null);
    const [wishlistEntries, setWishlistEntries] = useState([]);
    const didMount = useDidMount();
    const fullScreenDispatch = useDispatch();
    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const tyreSearchState = {
            tyres: [],
            filters: UrlFilterHelper.getFilterFromLocation(location, searchState.filters) ?? [
                {
                    orderPosition: 0,
                    collapsed: false,
                    defaultSelectedValues: null,
                    key: 'MIN_QUANTITY',
                    selectedValues: ["0"],
                    type: "RADIO_BUTTON",
                    values: ["0", "1", "2", "4", "8"]
                }
            ],
            selectedPage: UrlSearchParamsHelper.getPageFromLocation(location),
            count: 0,
            sort: UrlSortHelper.getSortFromLocation(location, initialSort),
            isCompleteWheelSearch: !!params.matchcodeFront,
            carId: params.carId ? params.carId : null,
            rimFrontId: params.rimFrontId ? params.rimFrontId : null,
            rimRearId: params.rimRearId ? params.rimRearId : null,
            selectedAmount: params.selectedAmount ? +params.selectedAmount : 4,
            positionId: params.positionId ? +params.positionId : null,
            breadcrumbLoading: false, ...UrlSearchParamsHelper.getTyreSearchState(location, params),
            reload: true,
        };

        dispatch({
            type: TyreSearchActionType.INIT,
            payload: tyreSearchState,
        });
        if(!!params.matchcodeFront) {
            loadRimAndCarDetails(tyreSearchState);
        }

        loadWishlist();

        fullScreenDispatch({
            type: FullScreenModeActionType.SET,
            payload: {isEnabled: false}
        });
        scrollToTopFn();
    }, []);

    useEffect(() => {
        if(searchState.reload) {
            loadTyres();
        }
    }, [searchState]);

    function loadRimAndCarDetails(tyreSearchState: TyreSearchState) {
        setBreadcrumbLoading(true);
        const rimReq = getRimDetails(tyreSearchState.carId, tyreSearchState.rimFrontId, tyreSearchState.rimRearId);
        const carReq = getCarDetails(decodeURIComponent(tyreSearchState.carId));
        Promise.all([rimReq, carReq])
            .then(([rimResult, carResult]) => {
                setRimDetails(rimResult);
                setCarDetails(carResult);
                return getMountedWheelCosts(rimResult?.rimFront?.size, rimResult?.rimRear?.size || rimResult?.rimFront?.size,);
            })
            .then((mountedWheelCosts) => {
                setMountedWheelCosts(mountedWheelCosts);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setBreadcrumbLoading(false);
            });
    }

    function loadTyres(showLoading: boolean = true) {
        if(showLoading) {
            setIsLoading(true);
        }

        const req = {
            search: searchState.search,
            filters: searchState.filters,
            sort: searchState.sort,
            offset: searchState.offset,
            limit: searchState.limit,
            shopMode: ShopMode.TYRES,
        };
        const targetSearch = searchState.isCompleteWheelSearch && searchState.isMixedTyreSearch ? searchMixedTyres : getTyres;
        targetSearch(req)
            .then((result) => {
                dispatch({
                    type: TyreSearchActionType.UPDATE_RESULTS,
                    payload: {
                        tyres: result.results,
                        count: result.count,
                        filters: result.filters,
                    },
                });
                AnalyticsHelper.trackTyreImpressions(result.results);
            })
            .finally(() => {
                setIsLoading(false);
                scrollToTopFn();
                setIsFirstSearch(false);
            });
    }

    function onPageChange(page) {
        dispatch({
            type: TyreSearchActionType.CHANGE_PAGE,
            payload: {
                selectedPage: page.selected,
            },
        });
        setUrlPage(page.selected);
    }

    function searchParamsCallback(search) {
        dispatch({
            type: TyreSearchActionType.CHANGE_SEARCH_PARAMS,
            payload: {
                search,
                isMixedTyreSearch: !!(search.frontMatchcode || search.rearMatchcode),
            },
        });
        setUrlPage(0);
    }

    function filterSelectionCallback(filters) {
        dispatch({
            type: TyreSearchActionType.CHANGE_FILTERS,
            payload: {
                filters,
            },
        });
        setUrlPage(0);
    }

    function onSortChange(value: string) {
        let direction = value.endsWith("DESC") ? SortDirection.DESC : SortDirection.ASC;

        const sort: SearchSort = {
            direction: direction,
            name: value.split("_")[0] as SortName,
        };
        UrlSortHelper.pushSortToHistory(location, navigate, sort);
        dispatch({
            type: TyreSearchActionType.CHANGE_SORT,
            payload: {
                sort: sort,
            },
        });
        loadTyres(false);
    }

    function loadWishlist(wishlistEntries?: WishListDTO[]) {
        if(wishlistEntries) {
            setWishlistEntries(wishlistEntries);
        } else {
            getWishlist().then((res) => {
                setWishlistEntries(res.wishlistEntries);
            });
        }
    }

    function setUrlPage(page: number | string) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", page.toString());

        navigate({search: searchParams.toString()}, {replace: true});
    }

    let carColor = new URLSearchParams(location.search).get("carColor");
    if (carColor === 'null') {
        carColor = undefined;
    }

    return (<div className="tyre-search-wrapper">
            <Helmet>
                <title>{t("TYRES.HTML_TITLE")}</title>
                <meta name="description" content={t("TYRES.HTML_TITLE")}/>
            </Helmet>

            {!searchState.isCompleteWheelSearch && <Breadcrumb breadcrumbs={[
                {
                    i18nKey: 'TYRES',
                }
            ]}/>}

            {searchState.isCompleteWheelSearch && (<>
                <CompleteWheelBreadcrumb
                    carDetails={carDetails}
                    rimDetails={rimDetails}
                    isLoading={breadcrumbLoading}
                    carId={searchState.carId}
                />
                <div className="d-flex align-items-baseline m-3">
                    <Link
                        to={`/felgen/details/${searchState.rimFrontId}${searchState.carId ? "/" + encodeURIComponent(searchState.carId) : ""}` + (carColor ? `?carColor=${carColor}` : '')}
                    >
                        <i className="fas fa-long-arrow-alt-left me-2"/>
                        {t("TYRES.SEARCH.BACK")}
                    </Link>
                </div>
            </>)}

            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12 col-lg-3 px-lg-0">
                        {!isLoading && (<TyreSearchParams
                            searchParams={searchState.search}
                            searchParamsCallback={searchParamsCallback}
                            isCompleteWheelSearch={searchState.isCompleteWheelSearch}
                        />)}

                        {!isSmallScreen && <Filter
                          filters={searchState.filters}
                          filterSelectionCallback={filterSelectionCallback}
                          side
                        />}
                    </div>
                    <div className="col-12 col-lg-9 pe-lg-0">
                        {isSmallScreen && <FilterBar
                          filterSelectionCallback={filterSelectionCallback}
                          filters={searchState.filters}
                          sort={searchState.sort}
                          sortChanged={(e) => onSortChange(e)}
                          isLoading={isLoading}
                        />}
                        <TyreResults
                            tyres={searchState.tyres}
                            count={searchState.count}
                            isCompleteWheelSearch={searchState.isCompleteWheelSearch}
                            isLoading={isLoading}
                            carId={searchState.carId}
                            rimFrontId={searchState.rimFrontId}
                            rimRearId={searchState.rimRearId}
                            sort={searchState.sort}
                            mountedWheelCosts={mountedWheelCosts}
                            rimDetails={rimDetails}
                            onSortChange={(e) => onSortChange(e)}
                            wishlistEntries={wishlistEntries}
                            getWishlistCallback={loadWishlist}
                            pageSize={searchState.limit}
                            firstSearch={isFirstSearch}
                            filterSelectionCallback={filterSelectionCallback}
                            filters={searchState.filters}
                        />
                        {!isLoading && searchState.tyres?.length > 0 && (<ReactPaginate
                                previousLabel={<i className="fas fa-angle-left"/>}
                                nextLabel={<i className="fas fa-angle-right"/>}
                                breakLabel="..."
                                breakClassName="break"
                                forcePage={searchState.selectedPage}
                                pageCount={Math.ceil(searchState.count / searchState.limit)}
                                marginPagesDisplayed={4}
                                pageRangeDisplayed={2}
                                onPageChange={onPageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                activeLinkClassName="active-link"
                                pageClassName="page"
                                pageLinkClassName="page-link"
                            />)}
                    </div>
                </div>
            </div>
        </div>);
}

export default TyreSearch;
