import React from "react";

import {useTranslation} from "react-i18next";
import {InputCheckbox} from './InputCheckbox';
import {InputInterface} from './Input';

interface InputCheckboxGroupProps extends InputInterface {
    euLabelOptions?: any
}

export function InputCheckboxGroup(props: InputCheckboxGroupProps) {
    const [t] = useTranslation();

    function isChecked(option: string){
        if(!props.value){
            return false;
        }

        return [...props.value].includes(option);
    }

    function onChange(option: string, checked: boolean){
        if (props.value?.includes(option)) {
            props.onChange(props.value.filter((v) => v !== option));
        } else {
            props.onChange([option].concat(props.value || []));
        }
    }

    return <div className={`checkbox-group`}>
        {props.options?.map((option: string) => {
            const euLabelOption = props.euLabelOptions?.find((o) => o.value === option);
            return (
                <InputCheckbox name={option} key={option}
                               checked={isChecked(option)}
                               onChange={(checked) => onChange(option, checked)} >
                    {!props.euLabelOptions && (props.translateValue ? t('INPUT.TRANSLATE_VALUES.' + option) : option)}
                    {euLabelOption && !euLabelOption.icon && <div className={'eu-label'} style={{
                        'width': euLabelOption.width * 25 + 'px',
                        '--euLabelColor': euLabelOption.color
                    } as React.CSSProperties}>
                        {props.translateValue ? t('INPUT.TRANSLATE_VALUES.' + option) : option}
                    </div>}

                    {euLabelOption && euLabelOption.icon && <div className={'eu-label icon'}>
                        <img src={euLabelOption.icon}/>
                        {props.translateValue ? t('INPUT.TRANSLATE_VALUES.' + option) : option}
                    </div>}
                </InputCheckbox>
            )
        })}
    </div>
}
