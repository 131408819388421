import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import AddedToCartModal from "../../../components/AddedToCartModal/AddedToCartModal";
import AmountInput from "../../../components/AmountInput/AmountInput";
import CustomModal from "../../../components/CustomModal/CustomModal";
import {scrollToTopFn} from "../../../components/ScrollToTop";
import {StockInfo} from "../../../components/StockInfo/StockInfo";
import {TradeName} from "../../../components/TradeName/TradeName";
import TyrePrice from "../../../components/TyrePrice/TyrePrice";
import {ShopMode} from "../../../models/shared/Enums";
import {TyreDTO} from "../../../models/tyres/TyreDTO";
import {updateCartPreview} from "../../../redux-store/actions/cartPreviewAction";
import {addTyreToShoppingCart} from "../../../redux-store/api/ShoppingCartApi";
import {getTyreDetails} from "../../../redux-store/api/TyreApi";
import TyreDetailsTable from "./TyreDetailsTable/TyreDetailsTable";
import Image from "../../../components/Image/Image";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import LoadingDetails from "../../../components/LoadingDetails/LoadingDetails";
import RecommendedRetailPrice from "../../../components/RecommendedRetailPrice/RecommendedRetailPrice";
import SeasonIcon from "../../../components/seasonIcon/SeasonIcon";
import RatePayInfo from "../../../components/RatePayInfo/RatePayInfo";

interface Props {
    updateCartPreview: Function;
}

const noImageUrl = "/no_images/reifen.svg";

function TyreDetails(props: Props) {
    const {seoId} = useParams<{ seoId: string }>();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [tyre, setTyre] = useState<TyreDTO>();
    const [amount, setAmount] = useState(4);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [id, setId] = useState("");
    const [availableQuantity, setAvailableQuantity] = useState(0);

    useEffect(() => {
        if (seoId) {
            setId(seoId.split("-")[0]);
        }
    }, [seoId]);

    useEffect(() => {
        loadTyreDetails();
        scrollToTopFn();
    }, [id]);

    function loadTyreDetails() {
        if (id.length == 0) {
            return;
        }
        setIsLoading(true);
        getTyreDetails(id, ShopMode.TYRES)
            .then((res) => {
                setTyre(res);
                setAvailableQuantity(res.availableQuantity);
                //trackTyre
            }).finally(
            () => {
                setIsLoading(false)
            },
        );
    }

    function handleAmountChange(e) {
        setAmount(e);
    }

    function addToCart() {
        setIsLoading(true);
        addTyreToShoppingCart(tyre, amount)
            .then((res) => {
                props.updateCartPreview();
                setIsLoading(false);
                setShowAddedToCartModal(true);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    function goBackToSearch() {
        navigate({
            pathname: `/reifen`,
            search: new URLSearchParams(location.search).toString(),
        });
    }

    return (
        <div className="tyre-details-wrapper">
            <Helmet>
                <title>{t("TYRES.HTML_TITLE")}</title>
                <meta name="description" content={tyre?.brandName + ' ' + tyre?.description}/>
            </Helmet>
            {tyre && tyre.productId ? (
                <TradeName>
                    {t("TYRES.DETAILS.TYRE")} {`${tyre.width}/${tyre.section} R${tyre.diameter}`}
                </TradeName>
            ) : (
                <TradeName>
                    {t("TYRES.DETAILS.TYRE")}
                </TradeName>
            )}
            {isLoading && <LoadingDetails/>}
            {!isLoading && tyre?.productId && (
                <div className={`container${isSmallScreen ? '-fluid' : ''} rim-details`}>
                    <div className="d-flex align-items-baseline my-3">
                        <i className="fas fa-long-arrow-alt-left"/>
                        <div className="ms-2 cursor" onClick={goBackToSearch}>
                            {t("TYRES.DETAILS.GO_BACK")}
                        </div>
                    </div>
                    <div className="border-box tyre-details-box">
                        <div className="box-title">
                            <div>
                                <span className="text-uppercase fw-bold">
                                    {tyre.brandName} {" "}
                                </span>
                                {tyre.tread}
                            </div>
                        </div>
                        <div className="box-content tyre-details-box-content">
                            <div className="row justify-content-end">
                                <div
                                    className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center justify-content-sm-start">

                                    <div className="position-relative tyre-image-container px-5 p-sm-0">
                                        <SeasonIcon season={tyre.season} size="2rem"
                                                    className="season-image position-absolute"/>
                                        <Image className="image"
                                               src={tyre.imageDTO.original}
                                               fallback={noImageUrl}
                                               alt={tyre.description}/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-31p tyre-detail">
                                    <TyreDetailsTable tyre={tyre}/>
                                    {tyre.tyreEULabelDTO?.eprelRegistrationId && (
                                        <div className="product-information-sheet mt-4 mb-3">
                                            <a
                                                href={`https://eprel.ec.europa.eu/qr/${tyre.tyreEULabelDTO?.eprelRegistrationId}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fas fa-file"/>{" "}
                                                {t("TYRES.DETAILS.PRODUCT_INFORMATION")}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-22p px-0 pe-md-2 pe-lg-0 text-center">
                                    {tyre.tyreEULabelDTO?.eprelRegistrationId ? (
                                        <img
                                            className="img-fluid eu-tyre-label"
                                            src={`/tyrelabel/v3?eprelid=${tyre.tyreEULabelDTO?.eprelRegistrationId}&width=500`}
                                            alt="EU-Reifenlabel"
                                        />
                                    ) : (
                                        <img
                                            className="img-fluid eu-tyre-label"
                                            src={`/tyrelabel/v1?rollresist=${tyre.tyreEULabelDTO?.rollingResistance}&wetgrip=${tyre.tyreEULabelDTO?.wetGrip}&noisedb=${tyre.tyreEULabelDTO?.noiseEmission}&noiseclass=${tyre.tyreEULabelDTO?.noiseClass}&tyreclass=${tyre.tyreEULabelDTO?.vehicleClass}&width=500`}
                                            alt="EU-Reifenlabel"
                                        />
                                    )}
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-22p">
                                    <div className="d-flex flex-column justify-content-end align-items-end h-100">
                                        <RecommendedRetailPrice
                                            price={tyre.pricesDTO?.minSellInPriceNet * amount}
                                            recommendedRetailPrice={tyre.pricesDTO?.recommendedRetailPrice * amount}/>
                                        <TyrePrice price={tyre.pricesDTO?.minSellInPriceNet}
                                                   isMountedWheel={false}
                                                   amount={amount}/>
                                        <StockInfo stock={tyre.availableQuantity}/>
                                        <RatePayInfo/>
                                        <div className="d-flex align-items-end justify-content-end mb-3 mt-3">
                                            <div className="me-2">
                                                {t("TYRES.DETAILS.AMOUNT_OF_TYRES")}
                                            </div>
                                            <AmountInput
                                                amount={amount}
                                                handleAmountChange={(e) => handleAmountChange(e)}
                                            />
                                        </div>

                                        <button className="btn btn-green mt-3" disabled={amount > availableQuantity}
                                                onClick={() => {
                                                    scrollToTopFn();
                                                    addToCart()
                                                }}>
                                            {t("TYRES.DETAILS.ADD_TO_CART")}
                                        </button>
                                        {
                                            amount > availableQuantity && <p className={'alert-primary alert mt-3'}>
                                                {t("TYRES.DETAILS.AMOUNT_NOT_AVAILABLE")}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomModal open={showAddedToCartModal}>
                        <AddedToCartModal
                            close={() => {
                                scrollToTopFn();
                                setShowAddedToCartModal(false);
                                scrollToTopFn()
                            }}
                        />
                    </CustomModal>
                </div>
            )}
        </div>
    );
}

const mapDispatchToProps = {
    updateCartPreview
};
export default connect(null, mapDispatchToProps)(TyreDetails);
