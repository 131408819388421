import React from "react";
import Slider from "react-slick";

function BrandPictures() {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerMode: true,
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <div className="brand-wrapper">
            <div className="container-fluid">
                <Slider {...settings}>
                    <div className="brand1"/>
                    <div className="brand2"/>
                    <div className="brand3"/>
                    <div className="brand4"/>
                    <div className="brand5"/>
                    <div className="brand6"/>
                </Slider>
            </div>
        </div>
    );
}

export default BrandPictures;
