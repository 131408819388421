import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlFilterHelper } from "../../helper/UrlFilterHelper";
import { useMediaQuery } from "../../helper/useMediaQuery";
import { FilterInterface } from "../../models/shared/FilterInterface";
import Input from "../Input/Input";

interface Props {
    side?: boolean;
    filters: FilterInterface[];
    filterSelectionCallback?: (filters: FilterInterface[]) => void;
    canCollapseSmall?: boolean;
    disableInstantSearch?: boolean;
    disableReload?: boolean;
    onFilterActivate?: Function;
}

export default function Filter(props: Props) {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(true);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [activeFilters, setActiveFilters] = useState<FilterInterface[]>(props.filters);

    useEffect(() => {
        setActiveFilters(props.filters);
    }, [props.filters]);

    function onFilterChange(values: string[], changedFilter: FilterInterface, instantSearch: boolean = true) {
        const newFilters = produce(activeFilters, (draft) => {
            draft.forEach((filter) => {
                if (filter.key === changedFilter.key) {
                    filter.selectedValues = values;
                }
            });
        });

        setActiveFilters(newFilters);

        if (instantSearch) {
            UrlFilterHelper.pushFiltersToHistory(location, navigate, newFilters);
            props.filterSelectionCallback(newFilters);
        }
    }

    function activateFilter() {
        UrlFilterHelper.pushFiltersToHistory(location, navigate, activeFilters);
        props.filterSelectionCallback(activeFilters);
        if (props.onFilterActivate) {
            props.onFilterActivate();
        }
    }

    function togglCollapsed() {
        setCollapsed(!collapsed);
    }

    const resetFilters = () => {
        const filters = produce(props.filters, (draft) => {
            draft.forEach((filter) => {
                filter.selectedValues = [];
            });
        });
        setActiveFilters(filters);
        UrlFilterHelper.pushFiltersToHistory(location, navigate, filters);
        props.filterSelectionCallback(filters);
    }

    if (activeFilters && activeFilters.length > 0) {
        return (
            <div className="search-result-filter">
                {props.side && <h3>{t('FILTER.TITLE')}</h3>}
                {props.canCollapseSmall && isSmallScreen && (
                    <div className="row click" onClick={togglCollapsed}>
                        <div className="col fw-bold">{t("FILTER.COLLAPSE_TITLE")}</div>
                        <div className="col text-end">
                            <i className={"fas " + (collapsed ? "fa-chevron-up" : "fa-chevron-down")}></i>
                        </div>
                    </div>
                )}
                {(!props.canCollapseSmall || !(props.canCollapseSmall && collapsed && isSmallScreen)) && (
                    <div className="row">
                        {activeFilters?.map((filter) => (
                            <div
                                className={
                                    props.side && !(isSmallScreen && props.canCollapseSmall)
                                        ? "col-12 filter-entry"
                                        : "col-12 col-sm-6 col-md-4 d-flex w-100 align-items-center filter-entry"
                                }
                                key={filter.key}
                            >
                                <Input
                                    label={t(`FILTER.${filter.key}`)}
                                    name={filter.key}
                                    type={filter.type}
                                    options={filter.values}
                                    value={filter.selectedValues}
                                    translateValue={filter.translateValue}
                                    onChange={(e) => onFilterChange(e, filter, !props.disableInstantSearch)}
                                />
                            </div>
                        ))}
                        <div className="col-12 mt-3">
                            <div
                                className={
                                    "row justify-content-lg-end justify-content-center align-items-lg-end align-items-center"
                                }
                            >
                                <button
                                    className="col-12 col-md-4 filter-btn btn me-md-3 my-1 p-1 btn-orange text-white"
                                    onClick={resetFilters}
                                >
                                    {t("FILTER.RESET")}
                                </button>

                                {props.disableInstantSearch && (
                                    <button
                                        className="col-12 col-md-4 filter-btn me-md-3 my-1 btn p-1 btn-green"
                                        onClick={activateFilter}
                                    >
                                        {t("FILTER.APPLY_FILTER")}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    } else if (!props.filters) {
        return (
            <div className="search-result-filter loading">
                <div className="row">
                    <div className={`col-12 ${props.side && !isSmallScreen ? "side" : ""}`} />
                </div>
            </div>
        );
    }
    return <></>;
}
