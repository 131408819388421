import React from 'react';
import {Breadcrumb as BootstrapBreadcrumb} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export interface BreadcrumbsProps {
    breadcrumbs?: IBreadcrumb[];
}
export interface IBreadcrumb {
    title?: string,
    i18nKey?: string,
    link?: string,
    icon?: string
}

function Breadcrumb (props: BreadcrumbsProps) {
    const {t} = useTranslation();

    const breadcrumbs = [
        {
            i18nKey: 'START_PAGE',
            link: '/'
        },
        ...props.breadcrumbs
    ]

    return (
        <BootstrapBreadcrumb className={'breadcrumb-container'}>
            {breadcrumbs?.map((bc, i) => {
                return <BootstrapBreadcrumb.Item key={i} href={bc.link} active={breadcrumbs.length === i+1}>
                    {bc.title ?? t('BREADCRUMBS.' + bc.i18nKey, t('NAVIGATION_BAR.' + bc.i18nKey))}
                    {bc.icon && <img src={bc.icon} style={{
                        height: "1rem",
                        width: "1rem",
                        marginLeft: "0.5rem"
                    }}/>}
                </BootstrapBreadcrumb.Item>
            })}
        </BootstrapBreadcrumb>
    );
}

export default Breadcrumb;
