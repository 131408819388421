import React, {
    useEffect,
    useState
} from "react";
import Helmet from "react-helmet";
import {
    Link,
    useNavigate
} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getOrderHistory} from "../../../redux-store/api/OrderApi";
import moment from 'moment';
import {NumericFormat} from 'react-number-format';
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {OrderHistoryDTO} from "../../../models/order/OrderHistoryDTO";
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

function OrderHistory() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState<OrderHistoryDTO[]>([]);
    const [loading, setLoading] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');

    useEffect(() => {
        loadOrderHistory();
    }, []);

    function loadOrderHistory() {
        setLoading(true);
        getOrderHistory().then((res) => {
            setOrders(res);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function selectOrder(order) {
        navigate(`/einstellungen/bestellungen/${order.id}`);
    }

    return (
        <div className="order-history-wrapper">
            <Helmet>
                <title>{t("SETTINGS.ORDER_HISTORY.TITLE")}</title>
            </Helmet>
            <Breadcrumb breadcrumbs={[
                {
                    i18nKey: 'ORDER_HISTORY'
                }
            ]}/>

            <div className="container my-3">
                <p><Link to="/einstellungen"><i className="fas fa-angle-left me-1"/>{t("SETTINGS.BACK")}</Link></p>
                {!loading &&
                    <>
                        {orders && orders.length > 0 &&
                            <div className="orders-overview">
                                {!isSmallScreen && <>
                                    <div className="row order-overview-header-row pt-3 pb-2">
                                        <div className="col-3 fw-bold">
                                            {t("SETTINGS.ORDER_HISTORY.DATE")}
                                        </div>
                                        <div className="col-2 fw-bold">
                                            {t("SETTINGS.ORDER_HISTORY.STATUS")}
                                        </div>
                                        <div className="col-2 fw-bold text-end">
                                            {t("SETTINGS.ORDER_HISTORY.ORDER_NUMBER")}
                                        </div>
                                        <div className="col-3 fw-bold text-end">
                                            {t("SETTINGS.ORDER_HISTORY.NUMBER_POSITIONS")}
                                        </div>
                                        <div className="col-2 fw-bold text-end">
                                            {t("SETTINGS.ORDER_HISTORY.PRICE_GROSS")}
                                        </div>
                                    </div>
                                    {orders.map(order => (
                                        <div className="row order-overview-panel" key={order.id}
                                             onClick={() => selectOrder(order)}>
                                            <div className="col-3">
                                                {moment(order.date).format('DD.MM.YYYY, HH:mm')} {t('GLOBAL.CLOCK')}
                                            </div>
                                            <div className="col-2">
                                                {t("ENUMS.ORDER_STATUS." + order.status)}
                                            </div>
                                            <div className="col-2 text-end">
                                                {order.id}
                                            </div>
                                            <div className="col-3 text-end">
                                                {order.positionCount}
                                            </div>
                                            <div className="col-2 text-end">
                                                <NumericFormat
                                                    value={order.sumGross}
                                                    displayType="text"
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix="€"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>}
                                {isSmallScreen && <>
                                    {orders.map(order => (
                                        <div className="row order-overview-panel mobile" key={order.id}
                                             onClick={() => selectOrder(order)}>
                                            <div className="col-6 fw-bold">
                                                {t("SETTINGS.ORDER_HISTORY.DATE")}
                                            </div>
                                            <div className="col-6">
                                                {moment(order.date).format('DD.MM.YYYY, hh:mm')}
                                            </div>
                                            <div className="col-6 fw-bold">
                                                {t("SETTINGS.ORDER_HISTORY.ORDER_NUMBER")}
                                            </div>
                                            <div className="col-6">
                                                {order.id}
                                            </div>
                                            <div className="col-6 fw-bold">
                                                {t("SETTINGS.ORDER_HISTORY.NUMBER_POSITIONS")}
                                            </div>
                                            <div className="col-6">
                                                {order.positionCount}
                                            </div>
                                            <div className="col-6 fw-bold">
                                                {t("SETTINGS.ORDER_HISTORY.STATUS")}
                                            </div>
                                            <div className="col-6">
                                                {t("ENUMS.ORDER_STATUS." + order.status)}
                                            </div>
                                            <div className="col-6 fw-bold">
                                                {t("SETTINGS.ORDER_HISTORY.PRICE_GROSS")}
                                            </div>
                                            <div className="col-6">
                                                <NumericFormat
                                                    value={order.sumGross}
                                                    displayType="text"
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix="€"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>}
                            </div>
                        }
                        {!orders || orders.length <= 0 && <>
                            <div>{t("SETTINGS.ORDER_HISTORY.NO_RESULTS")}</div>
                        </>}

                    </>
                }
                {loading &&
                    <div className="order-overview-loading"/>
                }
            </div>
        </div>
    );
}

export default OrderHistory;
