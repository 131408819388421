import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import {getFallbackImage} from "../../helper/Helper";
import {
    CartDTO,
    CartPositionDTO
} from "../../models/cart/CartDTO";
import {
    Axle,
    ItemType
} from "../../models/shared/Enums";
import {
    updatePositionQuantity,
    updatePositionTpms
} from "../../redux-store/api/ShoppingCartApi";
import AmountInput from "../AmountInput/AmountInput";
import Image from "../Image/Image";
import CarBuildDateInput from "../../pages/ShoppingCart/components/CarBuildDateInput/CarBuildDateInput";
import CarDescriptionInput from "../../pages/ShoppingCart/components/CarDescriptionInput/CarDescriptionInput";
import trashSvg from '../../assets/icons/svg/delete.svg'
import {TpmsItem} from '../../models/car/TpmsCheckDTO';
import {checkForTpms} from '../../redux-store/api/CarApi';
import TyreEuLabel from '../TyreEuLabel/TyreEuLabel';
import {ShoppingCartContext} from '../../pages/ShoppingCart/ShoppingCart';

interface Props {
    position: CartPositionDTO;
    updateCart?: (cart: CartDTO) => void;
    showInputs: boolean;
    removeButtonHandler?: Function;
    getAdditionalTyreForRimInCart?: Function;
}

function CartPositionMobile({
                                position,
                                updateCart,
                                showInputs,
                                removeButtonHandler,
                                getAdditionalTyreForRimInCart
                            }: Props) {
    const [t] = useTranslation();
    const [quantity, setQuantity] = useState(position.quantity);
    const [tpmsItems, setTpmsItems] = useState<TpmsItem[]>([]);
    const noTpmsId = "NO_TPMS";

    const {showLoader, hideLoader} = useContext(ShoppingCartContext);

    useEffect(() => {
        if(position.items.find(i => i.itemType === ItemType.TPMS)) {
            checkForTpms(position.carId).then((res) => {
                setTpmsItems(res.items);
            });
        }
    }, []);

    function getAdditionalTyre(rimId: string, certificateId: number, certificateBlockId: number) {
        getAdditionalTyreForRimInCart(rimId, certificateId, certificateBlockId, position.carId, position.quantity, position.id, position.boltCircle);
    }

    function changeQuantity(newQuantity: number) {
        showLoader();
        setQuantity(newQuantity);
        updatePositionQuantity(position, newQuantity)
            .then((cart) => {
                updateCart(cart);
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                hideLoader();
            });
    }

    function onChangeTpms(tpms) {
        showLoader();
        updatePositionTpms(position, tpms).then((cart) => {
            updateCart(cart);
            hideLoader();
        }).catch((err) => {
            console.error(err);
            hideLoader();
        });
    }

    return <div className="cart-position-mobile">
        {position.items.map((item, index, arr) => (<div key={item.id}>
            <title className={'mb-3'}>{t('CART.ITEM_TYPE.' + item.itemType)}</title>

            {showInputs && (item.itemType === ItemType.TPMS || item.itemType === ItemType.NO_TPMS) && tpmsItems && <div className={'d-flex gap-3 mb-3 mx-3'}>
                {tpmsItems.map(tpmsItem => <div key={tpmsItem.productId}>
                    <input
                        type="radio"
                        className="radio-button"
                        checked={tpmsItem.productId.toString() === item.productId}
                        onChange={() => onChangeTpms(tpmsItem)}
                        name={"tpms-" + item.id}
                        id={tpmsItem.productId.toString()}
                    />
                    <label className="radio-button-label ms-1" htmlFor={tpmsItem.productId.toString()} data-tooltip-id="tooltip-summer">
                        {tpmsItem.label}
                    </label>
                </div>)}

              <div>
                <input
                  type="radio"
                  className="radio-button"
                  onChange={(e) => onChangeTpms({itemType: noTpmsId})}
                  name="tpms"
                  id={noTpmsId}
                />
                <label className="radio-button-label ms-1" htmlFor={noTpmsId} data-tooltip-id="tooltip-summer">
                    {t('TPMS.DO_NOT_ADD_TPMS_OPTION')}
                </label>
              </div>
            </div>}

            <div className={'d-flex align-items-center mb-3 mx-3 gap-4'}>
                {item.itemType !== ItemType.MOUNTINGKIT && item.itemType !== ItemType.WHEELASSEMBLY && <Image src={item.imageLink}
                                                                                                              fallback={getFallbackImage(item)}
                                                                                                              alt={item.description}
                                                                                                              fluid={true}
                                                                                                              width={'100px'}
                />}
                <div className="item-description">
                    {item.itemType !== ItemType.WHEELASSEMBLY && <div className="item-title">
                        {item.manufacturer}
                    </div>}
                    {item.itemType === ItemType.RIM && <b>{item.productMetadata?.rimType}</b>}
                    {item.itemType === ItemType.TYRE && <b>{item.productMetadata?.tread}</b>}
                    {item.itemType === ItemType.TPMS && <b>{item.description}</b>}
                    {(item.itemType === ItemType.MOUNTINGKIT || item.itemType === ItemType.WHEELASSEMBLY) && <>{item.description}</>}

                    {item.itemType === ItemType.RIM && <>
                      <div
                        className="mb-3">{`${item.productMetadata.width}x${item.productMetadata.size} ET${item.productMetadata.offset}, ${item.productMetadata.boltPattern}`}</div>
                      <div>{item.productMetadata.colorGroup}</div>
                    </>}

                    {item.itemType === ItemType.TYRE && <>
                      <div className="mb-3">
                          {`${item.productMetadata.width}/${item.productMetadata.section}R${item.productMetadata.diameter} ${item.productMetadata.loadIndex}${item.productMetadata.speedIndex}`}
                      </div>
                      <div>{item.productMetadata.season ? t("TYRES.SEARCH.SEASONS." + item.productMetadata.season) : '&nbsp;'}</div>
                    </>}

                    {showInputs && item.itemType === ItemType.TPMS && <CarBuildDateInput item={item}/>}

                    {+item.productId > 0 && item.productEan && <div>{t("CART.ARTICLE_NUMBER")}:&nbsp;{item.productEan}</div>}

                    {(position.axle === "FRONT" || position.axle === "REAR") && (<div>{t("CART.AXLE." + position.axle)}</div>)}

                    {!showInputs && item.itemType === ItemType.TPMS && <div className="mt-3">
                        {t("CART.CAR_BUILD_DATE_PLACEHOLDER")}: {item.carBuildDate}
                    </div>}

                    {item.errorMessage && <div className="text-danger mt-2">{item.errorMessage}</div>}
                    {!item?.errorMessage && item.priceGross && item.priceGross > 0 && (<div className="d-flex justify-content-between mt-4">
                        <div className="">{t("CART.PIECE_PRICE")}</div>
                        <strong>
                            <NumericFormat
                                value={item.priceGross}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                suffix="€"
                            />
                        </strong>
                    </div>)}

                    {showInputs && position.positionType === ItemType.RIM && position.carId && position.axle === Axle.BOTH && <div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-3"
                        onClick={() => getAdditionalTyre(item.productExternalId, item.productMetadata.certificateId, item.productMetadata.certificateBlockId)}
                      >
                          {t("CART.ADD_TYRE")}
                      </button>
                    </div>}
                </div>


                {showInputs && position.positionType === ItemType.RIM && !position.carHsn && <CarDescriptionInput position={position}/>}
                {!showInputs && position.positionType === ItemType.RIM && !position.carHsn && <div className="car-description text-start mt-2">
                    {t("CART.CAR_DESCRIPTION")}: {position.carDescription}
                </div>}
            </div>

            <div className={'mb-3 mx-3'}>
                {item.tyreEULabel && <TyreEuLabel tyreEULabel={item.tyreEULabel}/>}
            </div>

            {item.itemType === ItemType.RIM && <div className="mb-3 mx-3 info-message">
              <i className="fas fa-info-circle info-icon"/>
                {t("CART.RIM_INCLUDES_SCREWS")}
            </div>}
        </div>))}
        <div className="position-action">
            <div className="row align-items-center">
                <div className="col-7">
                    {showInputs && <button className="icon-btn"
                                           onClick={() => removeButtonHandler(position)}>
                      <img src={trashSvg} className="filter-orange me-2"/> {t('CART.REMOVE_POSITION')}
                    </button>}
                </div>
                <div className="col d-flex justify-content-end">
                    <AmountInput
                        amount={quantity}
                        handleAmountChange={(amount) => changeQuantity(amount)}
                        onlyEvenNumbers={false}
                        readonly={!showInputs}
                    />
                </div>

            </div>
        </div>
        <div className="position-sum">
            {position.items.filter(i => i.priceGross).map((item, index, arr) => (<div className="d-flex justify-content-between mb-2" key={item.id}>
                {item.itemType === 'RIM' && <div>
                    {t('CART.RIM_SET')}:
                </div>}
                {item.itemType === 'TYRE' && <div>
                    {t('CART.TYRE_SET')}:
                </div>}
                {item.itemType === 'TPMS' && <div>
                    {t('CART.TPMS')}:
                </div>}
                {item.itemType === "MOUNTINGKIT" && <div>
                    {t('CART.MOUNTING_KIT')}:
                </div>}
                {item.itemType === 'WHEELASSEMBLY' && <div>
                    {t('CART.ASSEMBLY')}:
                </div>}
                <div className="fw-bold">
                    {item.priceGross && item.priceGross > 0 && <NumericFormat
                      value={item.priceGross * position.quantity}
                      displayType="text"
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      suffix="€"/>}

                </div>
            </div>))}
            <div className="d-flex justify-content-between">
                <div className="fw-bold">{t('CART.POSITION_SUM_MOBILE')}:</div>
                <div className="fw-bold">
                    <NumericFormat
                        value={position.sumGross}
                        displayType="text"
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix="€"/>
                </div>
            </div>
        </div>

    </div>;
}

export default CartPositionMobile;
