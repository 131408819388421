import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getCarClassesV3} from "../../redux-store/api/CarApi";
import {scrollToTopFn} from "../ScrollToTop";

interface Props {
    selectedManufacturerId: string;
    carClassSelected: Function;
    goBack: Function;
}

function CarClassSelection(props: Props) {
    const [search, setSearch] = useState();
    const [carClasses, setCarClasses] = useState<Array<string>>([]);
    const [results, setResults] = useState<Array<string>>([]);
    const [t] = useTranslation();

    useEffect(() => {
        scrollToTopFn();
        fetchCarClasses(props.selectedManufacturerId);
    }, [props.selectedManufacturerId]);

    function fetchCarClasses(manufacturerId: string) {
        getCarClassesV3(manufacturerId).then((res) => {
            setCarClasses(res);
            setResults(res);
        }).catch((error) => {
            console.error('fetchCarClasses', error);
        });
    }

    function filter(e) {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = carClasses.filter((entry) => {
                return entry.toLowerCase().includes(keyword.toLowerCase());
            });
            setResults(results);
        } else {
            setResults(carClasses);
        }
        setSearch(keyword);
    }

    function goBackToPreviousStep() {
        props.goBack();
    }

    return (
        <>
            <div className="car-class-selection row">
                <div className="col-12 mb-4 mt-3">
                    <h1 className="mb-2">{t('CAR_SELECTION.BY_MANUAL.CHOOSE_CAR_CLASS')}</h1>
                    <div>{props.selectedManufacturerId}</div>
                </div>
                <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <div className="back-button"
                             onClick={goBackToPreviousStep}>{t('CAR_SELECTION.BY_MANUAL.BACK')}</div>
                    </div>
                </div>
                <div className="filter-list mt-2">
                    <input
                        type="search"
                        value={search}
                        onChange={filter}
                        className="form-control mb-3"
                        placeholder={t('CAR_SELECTION.BY_MANUAL.FILTER_BY_CAR_CLASS')}
                    />
                    <ul className="list-group">
                        {results && results.length > 0
                            ? (
                                results.map((entry) => (
                                    <li key={entry} className={`list-group-item`}
                                        onClick={() => props.carClassSelected(entry)}>
                                        {entry}
                                    </li>
                                )))
                            : (
                                <h1 className="my-3 text-center">{t('CAR_SELECTION.CAR_LIST.NO_RESULTS')}</h1>
                            )}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default CarClassSelection;
