import SeasonIcon from "../../../../../components/seasonIcon/SeasonIcon";
import React from "react";
import Image from "../../../../../components/Image/Image";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import RatePayInfo from "../../../../../components/RatePayInfo/RatePayInfo";
import {SubTyreCardProps} from "./TyreCard";
import {useNavigate} from "react-router-dom";

export default function ListViewTyreCard(props: SubTyreCardProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const favText = props.isFavorite ? "FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES" : "FAVORITE.TOOLTIPS.ADD_TO_FAVORITES";

    enum TyreType {
        FRONT,
        REAR,
        ALL,
    }

    function getTyreData(tyreType: TyreType): { description: string, image: string, infos: any, tyreType: TyreType } {
        const targetTyre = tyreType == TyreType.REAR ? props.tyre.rearTyre : props.tyre;
        const targetImage = TyreType.REAR
            ? "rear_wheel"
            : TyreType.ALL ? "front_rear_wheels" : "front_wheel";

        return {
            description: `${targetTyre.width}/${targetTyre.section}R${targetTyre.diameter} ${targetTyre.loadIndex}${targetTyre.speedIndex}`,
            image: targetImage,
            infos: props.showTyreInfos(targetTyre, true, tyreType == TyreType.REAR),
            tyreType: tyreType
        }
    }

    function getTyreDescription(tyreData: { description: string, image: string, infos: any, tyreType: TyreType }) {
        return <div>
            <div className={`d-flex justify-content-end align-items-end`}>
                <img src={`/car_${tyreData.image}.svg`} className={"tcl-mobile__axis"}
                     alt={`car_${tyreData.image}.svg`}/>
                <span className={`small text-end`}>&nbsp;{tyreData.description}</span>
            </div>
            {getEULabel("column")}
            {tyreData.tyreType != TyreType.ALL &&
                <div className={"d-flex flex-row justify-content-end text-end align-items-end"}>
                    <span>{tyreData.infos}</span>
                </div>
            }
        </div>
    }

    const euLabel = props.tyre.tyreEULabelDTO;
    const labels: { src: string, infos: string }[] = [
        {src: "eu_tyre_label_rolling_resistance.svg", infos: euLabel.rollingResistance},
        {src: "eu_tyre_label_wet_grip.svg", infos: euLabel.wetGrip},
        {src: "eu_tyre_label_noise_emission.svg", infos: euLabel.noiseEmission + " dB"},
    ];

    function getEULabel(flexDirection: string = "row") {

        if (!props.tyre.tyreEULabelDTO) {
            return <></>
        }

        return <>
            {props.tyre.tyreEULabelDTO &&
                <div className="tyre-label w-100 ">
                    {/*<div className="tyre-label-heading w-100 my-1 text-end">{t("TYRES.SEARCH.EU_TYRE_LABEL")}</div>*/}
                    <div className={`d-flex w-100 justify-content-${props.smallScreen ? 'end' : 'start'}`}>
                        {labels.map((label, index) => getLabel(label.src, label.infos, index, flexDirection))}
                    </div>
                </div>
            }
        </>
    }

    function getLabel(src: string, infos: string, index: number, flexDirection: string) {
        return <div className={`d-flex flex-${flexDirection} align-items-center pe-2 `}
                    key={"list-view-eu-label-" + index + Math.random()}>
            <img src={`/${src}`} className="eu-tyre-label-icon" alt={src}/>
            <span>&nbsp;{infos}</span>
        </div>
    }

    function getTyreHtmlDesktop(tyreType: TyreType, textBlock: boolean = false) {
        const tyreData = getTyreData(tyreType);

        return (
            <div className={`tcl-tyre-infos__wheel`}>
                <img src={`/car_${tyreData.image}.svg`} className="axis mb-2 me-2" alt={`car_${tyreData.image}.svg`}/>
                <div className={`${textBlock ? 'text-block' : ''}`}>{tyreData.description}</div>
            </div>
        )
    }

    function getInnerContentDesktop() {
        return (
            <>
                {props.tyre.rearTyre
                    ? <>
                        {getTyreHtmlDesktop(TyreType.FRONT, true)}
                        {getEULabel()}
                        <hr/>
                        {getTyreHtmlDesktop(TyreType.REAR, true)}
                        {getEULabel()}
                    </>
                    : <>
                        {getTyreHtmlDesktop(TyreType.ALL)}
                        {getEULabel()}
                    </>
                }
            </>
        )
    }


    function onClickHandler() {
        if (props.isCompleteWheelSearch) {
            props.chooseTyreForMountedWheel();
        }
        props.detailsClicked();

        let url = {
            pathname: `/reifen/details/${props.tyre.productId}`,
            search: new URLSearchParams(location.search).toString(),
        }
        navigate(url);
    }

    function getDesktopCard() {
        return (
            <div className={"tyre-card tyre-card-list my-1 tyre-card-wrapper"}>
                <div className="tyre-card-list__image-wrapper">
                    <Image className="tyre-card-list__image" src={props.tyre.imageDTO.medium} fallback={props.noImageUrl}/>
                </div>
                <div className={"tyre-card-list__icon-container"}>
                    <SeasonIcon className={"me-2"} season={props.tyre.season} size="24px" margin={"0"}/>
                    <div className={`fav-icon`}>
                        <i
                            onClick={(evt) => props.changeFavorite(evt)}
                            className={`tyre-card-list__fav-icon ${props.isFavorite ? "fas fa-heart" : "far fa-heart"}`}
                            data-tooltip-id={"favorite-" + props.tyre.productId}
                        />
                        <ReactTooltip id={"favorite-" + props.tyre.productId} place="bottom">
                            <span>{t(favText)}</span>
                        </ReactTooltip>
                    </div>
                </div>

                <div className={"tyre-card-list__heading"}>
                    <h5 className={"m-0 text-dark"}>{props.tyre.brandName}</h5>
                    <p className={"m-0 small"}>{props.tyre.tread}</p>
                </div>
                <div className={`tyre-card-list__tyre-infos tcl-mixed-wheel-false small`}>
                    {getInnerContentDesktop()}
                </div>
                <div className={"tyre-card-list__price"}>
                    {props.showTyreInfos(props.tyre, true)}
                    <div className={"my-2"}/>
                    {props.tyre.rearTyre && props.showTyreInfos(props.tyre.rearTyre, true, true)}
                    <div className={"w-100 d-flex justify-content-end"}>
                        <RatePayInfo className={"small"}/>
                    </div>
                </div>
                <div className={"tyre-card-list__button-bar"}>
                    {props.showButton()}
                </div>
            </div>
        );
    }


    function getMobileCard() {
        return (
            <div className={"my-1 p-2 tyre-card-wrapper tcl-mobile"}>
                <div className={"tcl-mobile__main-content"}>
                    <div className={`tcl-mobile-main-content__tyre`}>
                        <SeasonIcon season={props.tyre.season} size="32px" margin={"0"}/>
                        <div className={`tcl-mobile-main-content-tyre__image p-2`}>
                            <Image className={"img"}
                                   src={props.tyre.imageDTO.medium}
                                   fallback={props.noImageUrl}/>
                        </div>

                        <div className={`fav-icon`}>
                            <i
                                onClick={(evt) => props.changeFavorite(evt)}
                                className={`${props.isFavorite ? "fas fa-heart" : "far fa-heart"}`}
                                data-tooltip-id={"favorite-" + props.tyre.productId}
                            />
                            <ReactTooltip id={"favorite-" + props.tyre.productId} place="bottom">
                                <span>{t(favText)}</span>
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className={"tcl-mobile-main-content__text-infos "}>
                        <div className={`tcl-mobile-main-content__heading`}>
                            <h3 className={`d-inline m-0 text-dark`}>{props.tyre.brandName}</h3>
                            <p className={"mb-0 small"}>{props.tyre.tread}</p>
                            {!props.tyre.rearTyre && getTyreDescription(getTyreData(TyreType.ALL))}
                            {props.tyre.rearTyre && <>
                                {getTyreDescription(getTyreData(TyreType.FRONT))}
                                <hr/>
                                {getTyreDescription(getTyreData(TyreType.REAR))}
                            </>}
                        </div>
                    </div>
                </div>
                {!props.tyre.rearTyre &&
                    <div className={"d-flex flex-row justify-content-between"}>
                        <div className={`small tcl-mobile__amount-text`}>
                            {t("TYRES.SEARCH." + (props.isMountedWheel ? 'FOR_AMOUNT_WHEELS' : 'FOR_AMOUNT_TYRES'), {amount: 4})}
                            {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}
                        </div>
                        <div className={"d-inline"}>
                            {props.showTyreInfos(props.tyre, true, false, true)}
                        </div>
                    </div>}
                <div className={"tyre-card-list__button-bar"}>
                    {props.showButton()}
                </div>
            </div>
        );
    }


    return props.smallScreen ? getMobileCard() : getDesktopCard();
}
