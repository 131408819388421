import React from "react";
import {useTranslation} from "react-i18next";
import {RimDetailDTO} from "../../../../models/rims/RimDetailDTO";

interface Props {
    rimDetails: RimDetailDTO;
}

function RimDetailsTable({rimDetails}: Props) {
    const [t] = useTranslation();

    function getRimImages(): { src: string, text: string }[] {
        let images: { src: string, text: string }[] = [];

        if (rimDetails.mixedDataRimInformation?.kitFront && rimDetails.mixedDataRimInformation?.kitRear) {
            if (rimDetails.mixedDataRimInformation.kitFront?.externalId !== rimDetails.mixedDataRimInformation.kitRear?.externalId) {
                images.push({
                    src: "/car_front_wheel.svg",
                    text: rimDetails.mixedDataRimInformation.kitFront?.externalId
                });
                images.push({
                    src: "/car_rear_wheel.svg",
                    text: rimDetails.mixedDataRimInformation.kitRear?.externalId
                });
                return images;
            }
        }

        images.push({
            src: "/car_front_rear_wheels.svg",
            text: rimDetails.mixedDataRimInformation.kitFront?.externalId
        });
        return images;
    }

    function showMontageKit() {
        return getRimImages().map((image, index) => {
            return <div key={"rim-details-image-wrapper-" + index} >
                <img src={image.src}
                     className="axis "
                     alt={image.src.replace("/", "")}
                     key={"rim-details-image-" + index}
                />
                <span className={"flex-wrap"} key={"rim-details-image-descr" + index}>
                    &nbsp;{image.text || t('RIMS.DETAILS.TABLE.SERIES_KIT')}
                </span>
            </div>
        })
    }

    function getPropertyRow(translationKey: string, index: number, value: any) {
        return (
            <div key={"rim-details-table-row-" + index} className={"rim-details-table__row small"}>
                <div key={"rim-details-table-tk-" + index} >
                    {t("RIMS.DETAILS.TABLE." + translationKey)}:
                </div>
                <div key={"rim-details-table-value-" + index} className="text-end fw-bold">
                    {value}
                </div>
            </div>
        )
    }

    const rimFront = rimDetails.rimFront;
    const propertyList: { translationKey: string, value: any, specialCondition?: boolean}[] = [
        {value: rimFront.rimManufacturer, translationKey: 'BRAND'},
        {value: rimFront.rimType, translationKey: 'DESIGN'},
        {value: rimFront.colorGroup, translationKey: 'COLORGROUP'},
        {value: rimFront.width, translationKey: 'WIDTH'},
        {value: rimFront.size, translationKey: 'SIZE', specialCondition: rimDetails?.rimDetailsSizes?.length <= 0},
        {value: rimFront.boltPattern, translationKey: 'BOLTPATTERN'},
        {value: rimFront.hubBoreHole, translationKey: 'HUB_BORE_HOLE'},
        {value: rimFront.offset, translationKey: 'OFFSET'},
        {value: showMontageKit(), translationKey: 'MONTAGE_KIT'},
    ];
    return (
        <div className="rim-details-table" >
            {propertyList.map((property, index) => {
                if (property.specialCondition == null || property.specialCondition) {
                    return getPropertyRow(property.translationKey, index, property.value)
                }
            })}
        </div>
    );
}

export default RimDetailsTable;
