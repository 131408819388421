import React, {useState} from "react";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    amount: number;
    handleAmountChange: Function;
    onlyEvenNumbers?: boolean;
    readonly?: boolean;
}

function AmountInput({amount, handleAmountChange, onlyEvenNumbers, readonly = false}: Props) {
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    const [newAmount, setNewAmount] = useState(amount);

    function onAmountChanged(newAmount: number) {
        if (newAmount < 1 && !onlyEvenNumbers) {
            setNewAmount(1);
        } else if (newAmount < 2 && onlyEvenNumbers) {
            setNewAmount(2);
        } else if (newAmount % 2 === 1 && onlyEvenNumbers) {
            if (newAmount > newAmount) {
                setNewAmount(+newAmount + 1);
            } else {
                setNewAmount(+newAmount - 1);
            }
        } else {
            setNewAmount(+newAmount);
        }
    }

    function increase () {
        handleAmountChange(newAmount+1);
        setNewAmount((prevState) => prevState + 1);
    }

    function decrease () {
        handleAmountChange(newAmount-1);
        setNewAmount((prevState) => prevState - 1);
    }

    return (
        <div className="custom-amount-input">
            <div className="amount-input-field-wrapper">
                <input type="number"
                       value={newAmount}
                       className="amount-field"
                       onChange={(e) => onAmountChanged(+e.target.value)}
                       onBlur={() => handleAmountChange(newAmount)}
                       disabled={readonly}
                />
            </div>
            {isSmallScreen &&
                <>
                    <div className="circle ms-2" onClick={decrease}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-minus  "/>
                        </div>
                    </div>
                    <div className="circle ms-2" onClick={increase}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-plus "/>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default AmountInput;
