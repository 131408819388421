import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import Input from "../../../../components/Input/Input";
import { UrlSearchParamsHelper } from "../../../../helper/UrlSearchParamsHelper";
import { RimTyreAssignmentDTO } from "../../../../models/rims/RimTyreAssignmentDTO";
import { RimTyreAssignmentRequest } from "../../../../models/rims/RimTyreAssignmentRequest";
import { TyreSearchParamDTO } from "../../../../models/tyres/TyreSearchRequestDTO";
import { getRimTyreAssignment } from "../../../../redux-store/api/RimApi";
import { getTyreSizes } from "../../../../redux-store/api/TyreApi";
import { TyreSizeSelect } from "./TyreSizeSelect";
import {InputCheckbox} from '../../../../components/Input/InputCheckbox';
import SeasonIcon from '../../../../components/seasonIcon/SeasonIcon';

type Props = {
    searchParams: TyreSearchParamDTO;
    searchParamsCallback: (searchParams: TyreSearchParamDTO) => void;
    isCompleteWheelSearch: boolean;
};

function TyreSearchParams({ searchParams, searchParamsCallback, isCompleteWheelSearch }: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUpdatingSizes, setIsUpdatingSizes] = useState<boolean>(false);
    const [widths, setWidths] = useState<string[]>([]);
    const [heights, setHeights] = useState<string[]>([]);
    const [diameters, setDiameters] = useState<string[]>([]);
    const [search, setSearch] = useState<TyreSearchParamDTO>(searchParams);
    const [rimTyreAssignments, setRimTyreAssignments] = useState<RimTyreAssignmentDTO[]>([]);
    const { carId, rimFrontId, rimRearId } = useParams<{ carId: string; rimFrontId: string; rimRearId: string }>();
    const [certificateId, setCertificateId] = useQueryParam("certificateId", StringParam);
    const [certificateBlockId, setCertificateBlockId] = useQueryParam("certificateBlockId", StringParam);
    const [selectedTyreSize, setSelectedTyreSize] = useState<RimTyreAssignmentDTO>();
    const [t] = useTranslation();

    useEffect(() => {
        loadRimTyreAssignments();
    }, []);

    useEffect(() => {
        if (isCompleteWheelSearch) {
            onSearch();
        } else {
            if (search.width && search.height && search.diameter) {
                onSearch();
            }
        }
    }, [search, isCompleteWheelSearch]);

    useEffect(() => {
        if (!isCompleteWheelSearch) {
            loadSizes();
        }
    }, [isCompleteWheelSearch]);

    function onSearch() {
        UrlSearchParamsHelper.pushSearchToHistory(location, navigate, search);
        if (search !== searchParams) {
            searchParamsCallback(search);
        }
    }

    function loadRimTyreAssignments() {
        setIsUpdatingSizes(true);
        const request: RimTyreAssignmentRequest = {
            carId,
            certificateBlockId: +certificateBlockId,
            certificateId: +certificateId,
            rimFrontId,
            rimRearId: rimRearId || rimFrontId,
        };
        getRimTyreAssignment(request)
            .then((res) => {
                setRimTyreAssignments(res);
                let size;
                if (search.matchcodeTerm) {
                    size = res.find((s) => s.front.matchcode === search.matchcodeTerm);
                } else if (search.frontMatchcode && search.rearMatchcode) {
                    size = res.find(
                        (s) => s.front.matchcode === search.frontMatchcode && s.rear.matchcode === search.rearMatchcode,
                    );
                }
                setSelectedTyreSize(size);
            })
            .finally(() => setIsUpdatingSizes(false));
    }

    function onChangeTyreSize(e: string[], size) {
        switch (size) {
            case "WIDTH":
                setSearch((search) => ({
                    ...search,
                    width: e?.length > 0 ? e[0] : null,
                }));
                break;
            case "HEIGHT":
                setSearch((search) => ({
                    ...search,
                    height: e?.length > 0 ? e[0] : null,
                }));
                break;
            case "DIAMETER":
                setSearch((search) => ({
                    ...search,
                    diameter: e?.length > 0 ? e[0] : null,
                }));
                break;
            default:
                break;
        }
    }

    function loadSizes() {
        setIsUpdatingSizes(true);
        getTyreSizes()
            .then((res) => {
                setHeights(res.heightList);
                setDiameters(res.diameterList);
                setWidths(res.widthList);
            })
            .finally(() => setIsUpdatingSizes(false));
    }

    function onChangeSeason(checked, season) {
        let newSeasons = [].concat(search.seasons);
        if (!checked) {
            newSeasons.splice(search.seasons.indexOf(season), 1);
        } else {
            newSeasons.push(season);
        }
        setSearch((search) => ({
            ...search,
            seasons: newSeasons,
        }));
    }

    function onChangeTyreSizeCompleteWheel(size: RimTyreAssignmentDTO) {
        if (size.front.matchcode === size.rear.matchcode) {
            setSearch((search) => ({
                ...search,
                matchcodeTerm: size.front.matchcode,
                frontMatchcode: null,
                rearMatchcode: null,
            }));
        } else {
            setSearch((search) => ({
                ...search,
                frontMatchcode: size.front.matchcode,
                rearMatchcode: size.rear.matchcode,
                matchcodeTerm: null,
            }));
        }
        setSelectedTyreSize(size);
    }

    return (
        <>
            {!isUpdatingSizes && (
                <div className="tyre-search-params">
                    <FormLabel>{t("TYRES.SEARCH.SEASON")}</FormLabel>
                    <div className={'row mb-3'}>
                        <div className={'col-6'}>
                            <InputCheckbox onChange={(e) => onChangeSeason(e, 'SUMMER')} name={'SUMMER'} checked={search.seasons?.includes("SUMMER")}>
                                <SeasonIcon season={'SUMMER'} size="32px" margin={"0 0.5rem 0 0"} /> {t('INPUT.TRANSLATE_VALUES.SUMMER')}
                            </InputCheckbox>
                        </div>
                        <div className={'col-6'}>
                            <InputCheckbox onChange={(e) => onChangeSeason(e, 'WINTER')} name={'WINTER'} checked={search.seasons?.includes("WINTER")}>
                                <SeasonIcon season={'WINTER'} size="32px" margin={"0 0.5rem 0 0"} /> {t('INPUT.TRANSLATE_VALUES.WINTER')}
                            </InputCheckbox>
                        </div>
                        <div className={'col-12'}>
                            <InputCheckbox onChange={(e) => onChangeSeason(e, 'ALL_SEASON')} name={'ALL_SEASON'} checked={search.seasons?.includes("ALL_SEASON")}>
                                <SeasonIcon season={'ALL_SEASON'} size="32px" margin={"0 0.5rem 0 0"} /> {t('INPUT.TRANSLATE_VALUES.ALL_SEASON')}
                            </InputCheckbox>
                        </div>
                    </div>
                    <div className="row">
                        {isCompleteWheelSearch && selectedTyreSize && rimTyreAssignments?.length > 0 && (<div className="col-12">
                            <div className="col-12">
                                <FormLabel>{t("TYRES.SEARCH.TYRE_SIZE")}</FormLabel>
                            </div>
                            <TyreSizeSelect
                                name="matchcode"
                                options={rimTyreAssignments}
                                value={selectedTyreSize}
                                onChange={(e) => onChangeTyreSizeCompleteWheel(e.value)}
                                required={true}
                            />
                            {selectedTyreSize.abe && (<div className="tyre-info-green">
                                    <i className="fa fa-check me-2"/>
                                    {t("RIMS.RIM_TYRE_SIZE_DIALOG.ABE")}
                                </div>)}
                            {selectedTyreSize.snowChain && (<div className="tyre-info-green">
                                    <i className="fa fa-check me-2"/>
                                    {t("RIMS.RIM_TYRE_SIZE_DIALOG.SNOW_CHAIN")}
                                </div>)}
                        </div>)}
                        {!isCompleteWheelSearch && (<>
                            <div className="col-12">
                                <FormLabel>{t("TYRES.SEARCH.TYRE_SIZE")}</FormLabel>
                            </div>
                            <div className="col-4 col-lg-12 col-xl-4 pe-xl-1">
                                <Input
                                    type="SINGLE_SELECT_DROPDOWN"
                                    labelClass="d-none d-lg-block d-xl-none"
                                    label={t("TYRES.SEARCH.WIDTH")}
                                    name="width"
                                    options={widths}
                                    value={[search.width]}
                                    hideClear={true}
                                    onChange={(e) => onChangeTyreSize(e, "WIDTH")}
                                />
                            </div>
                            <div className="col-4 col-lg-12 col-xl-4 px-xl-1">
                                <Input
                                    type="SINGLE_SELECT_DROPDOWN"
                                    labelClass="d-none d-lg-block d-xl-none"
                                    label={t("TYRES.SEARCH.SECTION")}
                                    name="height"
                                    options={heights}
                                    hideClear={true}
                                    value={[search.height]}
                                    onChange={(e) => onChangeTyreSize(e, "HEIGHT")}
                                />
                            </div>
                            <div className="col-4 col-lg-12 col-xl-4 ps-xl-1">
                                <Input
                                    type="SINGLE_SELECT_DROPDOWN"
                                    labelClass="d-none d-lg-block d-xl-none"
                                    label={t("TYRES.SEARCH.DIAMETER")}
                                    name="diameter"
                                    options={diameters}
                                    hideClear={true}
                                    value={[search.diameter]}
                                    onChange={(e) => onChangeTyreSize(e, "DIAMETER")}
                                />
                            </div>
                            {/*<div className="col-12">
                                <button className="btn btn-primary mt-2 w-100" onClick={() => onSearch()}>
                                    {t("TYRES.SEARCH.APPLY")}
                                </button>
                            </div>*/}
                        </>)}
                    </div>
                </div>)}
            {isUpdatingSizes && <div className="tyre-search-params loading" />}
        </>
    );
}

export default TyreSearchParams;
