import React, {useEffect, useState} from "react";
import {getCarHistory} from "../../redux-store/api/CarApi";
import {ReactSelectOption} from "../../models/shared/ReactSelectOption";
import Select from "react-select";
import {getReactSelectStyles} from "../../helper/Helper";
import {useTranslation} from "react-i18next";

interface Props {
    optionSelected: Function;
}
function CarHistorySelect(props: Props) {
    const [carHistoryOptions, setCarHistoryOptions] = useState<ReactSelectOption[]>([]);
    const [t] = useTranslation();
    const [selectedCar, setSelectedCar] = useState<ReactSelectOption>();

    useEffect(() => {
        loadCarHistory();
    }, []);

    function loadCarHistory() {
        getCarHistory().then((res) => {
            const options = res?.map((car) => {
                const pitchCircle = car.pitchCircle?.replace(/\s+/g, "");
                return {
                    value: car.carId + "_" + pitchCircle,
                    label: car.tradeName + " (" + car.hsn + "/" + car.tsn + ")",
                };
            });
            setCarHistoryOptions(options);
            if (options && options.length > 0) {
                setSelectedCar(options[0]);
            }
        });
    }

    return (
        <>
            {carHistoryOptions && carHistoryOptions.length > 0 && <div className="mt-1 mb-3">
              <div className={'car-history-content'}>
                  <h3 className="mb-2">{t('CAR_SELECTION.INPUT.LAST_CARS')}</h3>
                  <Select
                    options={carHistoryOptions}
                    defaultValue={carHistoryOptions[0]}
                    placeholder={t("CAR_SELECTION.INPUT.LAST_CARS")}
                    styles={getReactSelectStyles()}
                    onChange={(e) => setSelectedCar(e)}
                  />
              </div>
              <button className={'btn btn-green w-100 mt-2'} onClick={() => props.optionSelected(selectedCar)}>{t('CAR_SELECTION.BTN.CONTINUE_VEHICLE')}</button>
            </div>}
        </>
    )
}

export default CarHistorySelect;
