import React, { useContext, useState } from "react";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import {CartPositionDTO} from "../../../../models/cart/CartDTO";
import {updateCarDescription} from "../../../../redux-store/api/ShoppingCartApi";
import { ShoppingCartContext } from "../../ShoppingCart";

interface Props {
    position: CartPositionDTO;
}

export default function CarDescriptionInput({position}: Props) {
    const [t] = useTranslation();
    const [submitText, setSubmitText] = useState<string>("");
    const {changeLoadingCounter, cartChanged} = useContext(ShoppingCartContext);
    let timeout: any;

    function submitForm(values: FormikValues) {
        setSubmitText(t('CART.DATA_SUBMITTING'));
        changeLoadingCounter(true)
        updateCarDescription(position.id, values.carDescription).then((res) => {
            cartChanged(res);
            setSubmitText(t('CART.DATA_SUBMIT_SUCCESS', {0: values.carDescription}));
        }).catch(e => {
            console.log(e);
            setSubmitText(t('CART.DATA_SUBMIT_ERROR'));
        }).finally(() => {
            changeLoadingCounter(false)
            if(timeout){
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                setSubmitText("");
            }, 5000);
        });
    }

    const initialValues = {
        carDescription: position.carDescription || ""
    };
    return (
        <div className="car-description mt-3">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={submitForm}
            >
                {(props: FormikProps<any>) => (
                    <Form>
                        <div>
                            <div className={`d-flex info-text`}>
                                <div className={`${!position.carDescription ? 'text-danger' : ''} mt-2`}>
                                    {t('CART.CAR_DESCRIPTION_INFO')}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="input-group">
                                    <Field autoComplete="off"
                                           className="form-control"
                                           type="text"
                                           name="carDescription"
                                           required
                                    />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-primary">
                                            {t('GLOBAL.BTN.CONFIRM')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>{submitText}</div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}


