import React from "react";
import { Helmet } from "react-helmet";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { scrollToTopFn } from "../../../components/ScrollToTop";
import { SharedBlockSlider, SharedBlockType } from "../../../models/strapi/SharedBlocks";
import {
    StrapiMediaAttributes
} from "../../../models/strapi/StrapiMediaContainer";
import { getStaticPage } from "../../../redux-store/api/StrapiApi";
import { GalleryModal } from "./GalleryModal";
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

interface GalleryState {
    galleryImages: StrapiMediaAttributes[];
    zoomedImage?: StrapiMediaAttributes;
}

class Gallery extends React.Component<any, GalleryState> {
    constructor(props) {
        super(props);
        this.state = { galleryImages: [] };
    }

    componentDidMount() {
        scrollToTopFn();
        getStaticPage("gallery").then((res) => {
            const imageBlock = res?.attributes?.blocks.find(
                (e): e is SharedBlockSlider => e.__component === SharedBlockType.SLIDER,
            );
            console.log(imageBlock, res)
            if (imageBlock?.files?.data.length > 0) {
                this.setState({
                    galleryImages: imageBlock.files.data.map(i => i.attributes),
                });
            } else {
                this.setState({ galleryImages: [] });
            }
        });
    }

    render() {
        return (
            <div className="gallery">
                <Helmet>
                    <title>Galerie</title>
                    <meta name="description" content="Galerie" />
                </Helmet>
                <Breadcrumb breadcrumbs={[{i18nKey: 'GALLERY'}]}/>
                <div className="container grid my-5">
                    {this.state.galleryImages.map((img, index) => (
                        <div
                            key={img.url}
                            style={{ backgroundImage: "url(/cms" + img.url + ")" }}
                            className={"img"}
                            onClick={() => this.setState({ zoomedImage: img })}
                        >
                            <i className="zoom fas fa-search fa-3x"></i>
                        </div>
                    ))}
                </div>

                <CustomModal open={!!this.state.zoomedImage} backdropClick={() => this.closeModal()}>
                    <GalleryModal close={() => this.closeModal()} img={'/cms'+this.state.zoomedImage?.url} alt={this.state.zoomedImage?.alternativeText} />
                </CustomModal>
            </div>
        );
    }

    private closeModal() {
        this.setState({ zoomedImage: undefined });
    }
}

export default Gallery;
