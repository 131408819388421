import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { getAvailableColors } from "../../bmf3dImages/api/configurator.api";
import {IRimConfig} from "../../bmf3dImages/interfaces/rim-config.interface";
import {showWinterImage} from "../../helper/Helper";
import {CarDetailDTO} from "../../models/car/CarDetailDTO";
import {RimDTO} from "../../models/rims/RimSearchResultDTO";
import {getCarImage} from "../../redux-store/api/CarApi";
import {RimDetailDTO, RimGroupSearchResultDTO, RimItemDTO} from "../../models/rims/RimDetailDTO";
import BoxedCarView from "./BoxedCarView";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "../../helper/useMediaQuery";
import {Orientation, useOrientation} from "../../helper/useOrientation";
import { CarColors, CarImageDTO } from "../../models/car/CarImageDTO";
import MobileLandscapeCarView from "./MobileLandscapeCarView";
import DesktopCarView from "./DesktopCarView";
import {RimSizeAssignmentDTO} from "../../models/rims/RimSizeAssignmentDTO";

interface Props {
    carTradeName: any;
    carId: string;
    selectedRimId: string;
    selectedRimGroup?: RimDTO;
    changeCar?: any;
    changeRimSize?: (item: RimItemDTO) => void,
    carDetails: CarDetailDTO;
    boxView?: boolean;
    selectedRim?: RimItemDTO;
    rimGroupSearchResultDTO?: RimGroupSearchResultDTO;
    fullscreen?: boolean;
    toggleFullscreen?: Function;
    rimDetails?: RimDetailDTO;
    rimSizes?: any[];
    setSelectedRimSizeHandler?: Function;
    selectedRimSize?: RimSizeAssignmentDTO;
    rimSizeAssignments?: RimSizeAssignmentDTO[];
    carImages?: any[];
}

interface State {
    selectedColorId: string;
    selectedBackground: string;
    selectedLowering: number;
    showCarConfigurationId: string;
    isSmallScreen: boolean;
    carImage?: CarImageDTO;
    error?: any;
    canBeLowered?: boolean;
    showCarDetailsModal?: boolean;
    rimConfigData: IRimConfig;
    use3d: boolean;
}

export enum ChangedCarViewOption {
    BACKGROUND,
    COLOR,
    LOWERING
}

export interface SubCarViewProps {
    isSmallScreen?: boolean;
    fullscreen?: boolean;
    toggleFullScreenMode?: Function;
    use3d: boolean;

    carId: string;
    rimId: string;
    selectedRimGroup: RimDTO;
    changeRimSize: (item: RimItemDTO) => void;
    editCar: () => void;


    carBackgrounds: string[];
    selectedBackground: string;

    selectedLowering: number;
    loweringMin: number;
    loweringMax: number;

    setCanBeLowered: (bool) => void;

    selectedColorId: string;
    carColors: CarColors[];

    carImage: CarImageDTO;
    imageLoaded: (IRimConfig) => void;
    closeConfigOnOutsideClick: (any) => void;
    onChangeOptionHandler: (ChangedCarViewOption, any) => void;
    carImageUrl: string;

    openCarDetails: () => void;
    showCarDetails: boolean;
    toggleCarDetails: () => void;
    carDetails: CarDetailDTO;
    rimConfigData: IRimConfig;

    rimDetails?: RimDetailDTO;
    rimSizes?: any[];
    setSelectedRimSizeHandler?: Function;
    selectedRimSize?: RimSizeAssignmentDTO;
    rimSizeAssignments?: RimSizeAssignmentDTO[];
    carImages?: any[];
}

export default function CarView(props: Props, state: State) {
    const carBackgrounds = ["default", "city-day", "snow", "out-of-town", "city-night", "none"];
    const [t] = useTranslation();
    const loweringMin = 0;
    const loweringMax = 15;
    const location = useLocation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [selectedColorId, setSelectedColorId] = useState(new URLSearchParams(location?.search).get("carColor"));
    const [carColors, setCarColors] = useState<CarColors[]>([]);
    const [selectedBackground, setSelectedBackground] = useState(getInitialCarBackground());
    const [selectedLowering, setSelectedLowering] = useState(getInitialLowering());
    const [showCarConfigurationId, setShowCarConfigurationId] = useState(null);
    const [rimConfigData, setRimConfigData] = useState<IRimConfig>(null);
    const [use3d, setUse3d] = useState(true);
    const [carImage, setCarImage] = useState(state.carImage);
    const [error, setError] = useState();
    const [showCarDetailsModal, setShowCarDetailsModal] = useState(false);
    const navigate = useNavigate();
    const [canBeLowered, setCanBeLowered] = useState(false);
    const orientation = useOrientation();

    useEffect(() => {
        if (!props.boxView) {
            getCarImageFromAPI();
            getCarColors();
        }
    }, []);

    function getInitialCarBackground() {
        const bg = new URLSearchParams(location?.search).get("carBg");
        if (carBackgrounds.includes(bg)) {
            return bg;
        } else if (showWinterImage()) {
            return carBackgrounds[2];
        } else {
            return carBackgrounds[0];
        }
    }

    function getInitialLowering() {
        const lowering = +new URLSearchParams(location?.search).get("carLow");
        if (lowering >= 0 && lowering <= 15) {
            return lowering;
        } else {
            return 0;
        }
    }

    function getCarImageFromAPI() {
        getCarImage(props.carId, props.selectedRimId)
            .then((res) => {
                const selectedColorId = new URLSearchParams(location?.search).get("carColor") ? new URLSearchParams(location?.search).get("carColor") :
                    state.selectedColorId || res.colors[0].colorId;
                setCarImage(res);
                setSelectedColorId(selectedColorId);
                _afterSearchWorkflow(ChangedCarViewOption.COLOR, selectedColorId);
            })
            .catch((error) => {
                setError(error);
            });
    }

    function getCarColors(){
        const carId = props.carId?.split("_")[0];
        getAvailableColors(carId)
            .then(colors => {
                setCarColors(
                    colors.map((c) => ({
                        colorId: c.colorName,
                        colorPlateImageUrl: "/api/bmf/image/de/colorPlate/" + c.colorName,
                    })),
                );
            })
    }

    function editCar() {
        if (props.changeCar) {
            props.changeCar();
        }
    }

    function openCarDetailsModal() {
        setShowCarDetailsModal(true);
    }

    function toggleCarDetailsModal() {
        setShowCarDetailsModal(!showCarDetailsModal);
    }

    function showCarConfiguration(carConfigurationId) {
        if (carConfigurationId === showCarConfigurationId) {
            carConfigurationId = null;
        }
        setShowCarConfigurationId(carConfigurationId);
    }

    function changeOptionHandler(selectedOption: ChangedCarViewOption, e: any) {
        const search = new URLSearchParams(location.search);
        let targetParam = _getTargetParam(selectedOption);

        search.set(targetParam, e);
        navigate({
            search: search.toString()
        }, {replace: true});

        _afterSearchWorkflow(selectedOption, e);

    }

    function _afterSearchWorkflow(selectedOption: ChangedCarViewOption, e: any) {
        switch (selectedOption) {
            case ChangedCarViewOption.BACKGROUND:
                setSelectedBackground(e);
                break;
            case ChangedCarViewOption.COLOR:
                setSelectedColorId(e);
                break;
            case ChangedCarViewOption.LOWERING:
                setSelectedLowering(e);
                break;
        }
    }

    function _getTargetParam(selectedOption: ChangedCarViewOption): string {
        switch (selectedOption) {
            case ChangedCarViewOption.BACKGROUND:
                return 'carBg';

            case ChangedCarViewOption.COLOR:
                return 'carColor';

            case ChangedCarViewOption.LOWERING:
                return 'carLow';
        }
    }

    function closeConfigOnOutsideClick(evt: React.MouseEvent<HTMLDivElement>) {
        if (showCarConfigurationId && typeof showCarConfigurationId === "string") {
            const currentConfig = document.getElementById(showCarConfigurationId)
            const target = evt.target;
            if (target instanceof Node && !currentConfig.contains(target)) {
                showCarConfiguration(null);
            }
        }
    }

    function toggleFullScreenMode() {
        if (props.toggleFullscreen) {
            props.toggleFullscreen();
        }
    }

    function toggl3DMode() {
        setUse3d(!use3d);
    }

    function render() {
        const rimId = props.selectedRimId || "0S121608021709390071000769"; // Use steel rim for image when no rim is selected
        const {selectedRimGroup} = props;
        let carImageUrl = '';
        if (props.carId) {
            carImageUrl = `/api/image/carpreview/${decodeURIComponent(props.carId).split("_")[0]}/${rimId}/${rimId}/${selectedColorId}/${selectedLowering}`;
        }
        let carId = props.carId.split("_")[0];
        let visibleCarColors = carColors?.length > 0 ? carColors : (carImage && carImage.colors ? carImage.colors : []);

        if (props.boxView && props.selectedRimGroup && !isSmallScreen) {
            return (
                <BoxedCarView selectedRimGroup={props.selectedRimGroup}
                              selectedRim={props.selectedRim}
                              changeRimSize={props.changeRimSize}
                              carId={props.carId}
                              rim={props.rimGroupSearchResultDTO}
                              use3d={use3d}
                              carColor={selectedColorId}
                              lowering={selectedLowering}
                              setCanBeLowered={(can) => setCanBeLowered(can)}
                              imageLoaded={(data) => setRimConfigData(data)}
                              carImageUrl={carImageUrl}
                              carDetails={props.carDetails}
                />
            );
        }

        if (!props.boxView && isSmallScreen && props.fullscreen && orientation.type == Orientation.LANDSCAPE) {
            return (
                <MobileLandscapeCarView rimId={rimId}
                                        selectedRimGroup={props.selectedRimGroup}
                                        carImage={carImage}
                                        carId={carId}
                                        use3d={use3d}
                                        changeRimSize={props.changeRimSize}
                                        carBackgrounds={carBackgrounds}
                                        selectedBackground={selectedBackground}
                                        selectedLowering={selectedLowering}
                                        loweringMin={loweringMin}
                                        loweringMax={loweringMax}
                                        setCanBeLowered={(can) => setCanBeLowered(can)}
                                        selectedColorId={selectedColorId}
                                        carColors={visibleCarColors}
                                        imageLoaded={(data) => setRimConfigData(data)}
                                        closeConfigOnOutsideClick={(evt) => closeConfigOnOutsideClick(evt)}
                                        onChangeOptionHandler={(option, e) => changeOptionHandler(option, e)}
                                        openCarDetails={openCarDetailsModal}
                                        editCar={editCar}
                                        showCarDetails={showCarDetailsModal}
                                        carDetails={props.carDetails}
                                        carImageUrl={carImageUrl}
                                        rimConfigData={rimConfigData}
                                        toggleCarDetails={toggleCarDetailsModal}
                                        fullscreen={props.fullscreen}
                                        toggleFullScreenMode={props.toggleFullscreen}
                                        rimDetails={props.rimDetails}
                                        rimSizes={props.rimSizes}
                                        selectedRimSize={props.selectedRimSize}
                                        setSelectedRimSizeHandler={props.setSelectedRimSizeHandler}
                                        rimSizeAssignments={props.rimSizeAssignments}
                                        carImages={props.carImages}
                />
            );
        }

        if (!props.boxView && isSmallScreen && props.fullscreen && orientation.type != Orientation.LANDSCAPE) {
            return (
                <div className="flip-phone-container">
                    <div className="p-3" onClick={() => toggleFullScreenMode()}><i
                        className="fas fa-long-arrow-alt-left me-2"/>{t('CAR_VIEW.BACK_TO_OVERVIEW')}</div>
                    <div className="d-flex flex-column align-items-center px-5 py-4">
                        <div className="flip-phone">
                            <img className="img-fluid" src="/Wheelscompany_flip_vs1.svg" alt="flip_phone"/>
                        </div>
                        <div className="flip-phone-msg">{t('CAR_VIEW.FLIP_PHONE')}</div>
                    </div>
                </div>
            )
        }

        //&& !props.boxView is needed because otherwise the old preview is visible for a moment, if boxView is enabled
        return props.carTradeName && !props.boxView ? (
            <DesktopCarView rimId={rimId}
                            selectedRimGroup={props.selectedRimGroup}
                            carImage={carImage}
                            carId={carId}
                            use3d={isSmallScreen ? false : use3d}
                            changeRimSize={props.changeRimSize}
                            carBackgrounds={carBackgrounds}
                            selectedBackground={selectedBackground}
                            selectedLowering={selectedLowering}
                            loweringMin={loweringMin}
                            loweringMax={loweringMax}
                            setCanBeLowered={(can) => setCanBeLowered(can)}
                            selectedColorId={selectedColorId}
                            carColors={visibleCarColors}
                            imageLoaded={(data) => setRimConfigData(data)}
                            closeConfigOnOutsideClick={(evt) => closeConfigOnOutsideClick(evt)}
                            onChangeOptionHandler={(option, e) => changeOptionHandler(option, e)}
                            openCarDetails={openCarDetailsModal}
                            editCar={editCar}
                            showCarDetails={showCarDetailsModal}
                            carDetails={props.carDetails}
                            carImageUrl={carImageUrl}
                            rimConfigData={rimConfigData}
                            toggleCarDetails={toggleCarDetailsModal}
                            toggleFullScreenMode={toggleFullScreenMode}
                            fullscreen={props.fullscreen}
                            isSmallScreen={isSmallScreen}
            />
        ) : (
            <div id="car-view" className="loading"/>
        );
    }

    return (
        <>
            {render()}
        </>
    )
}


