import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import {
    useTranslation
} from "react-i18next";
import { InputType } from "../../models/shared/Types";
import ColorSelection from "../ColorSelection/ColorSelection";
import RimSizeSelection from "../RimSizeSelection/RimSizeSelection";
import { InputCheckbox } from "./InputCheckbox";
import { InputHelper } from "./InputHelper";
import { InputSelect } from "./InputSelect";
import { InputSlider } from "./InputSlider";
import {InputCheckboxGroup} from './InputCheckboxGroup';

import noiceClassASvg from '../../assets/icons/svg/NOISE_CLASS_A.svg';
import noiceClassBSvg from '../../assets/icons/svg/NOISE_CLASS_B.svg';
import noiceClassCSvg from '../../assets/icons/svg/NOISE_CLASS_C.svg';

export interface InputInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value?: string[];
    defaultValue?: any[];
    label?: string;
    onChange?: InputChanged;
    options?: string[];
    type: InputType;
    translateValue?: boolean;
    translatePrefix?: string;
    formatNumber?: boolean;
}
export type InputChanged = (newValues: string[]) => void;

interface Props extends InputInterface {
    labelClass?: string;
    hideLabel?: boolean;
    hideClear?: boolean;
}

export default function Input(props: Props) {
    const [t] = useTranslation();

    const onChangeSingleCheckbox = (e, name) => {
        const valuesTmp: string[] = [];
        if (e) {
            valuesTmp.push(name);
        }

        props.onChange(valuesTmp);
    }

    const getInputByType = () => {
        switch (props.type) {
            case "SINGLE_SELECT_DROPDOWN":
                return <InputSelect isMulti={false} {...props} />;
            case "MULTI_SELECT_DROPDOWN":
                if (props.name == "COLOR_GROUP") {
                    return (
                        <ColorSelection
                            containerClasses={""}
                            objectClasses={"m-1"}
                            multiSelect={true}
                            onSelect={props.onChange}
                            colors={props.options}
                            values={props.value}
                        />
                    );
                }
                return <InputSelect isMulti={true} {...props} />;
            case "MULTI_SLIDER":
            case "SINGLE_SLIDER":
                const min = Number(props.options[0]);
                const max = Number(props.options[1]);
                const defaultMin = Number(props.value?.length > 0 ? props.value[0] : props.options[0]);
                const defaultMax = Number(props.value?.length > 0 ? props.value[1] : props.options[1]);
                return (
                    <InputSlider
                        range={props.type === "MULTI_SLIDER"}
                        name={props.name}
                        min={min}
                        max={max}
                        defaultMin={defaultMin}
                        defaultMax={defaultMax}
                        onChange={props.onChange}
                    />
                );
            case "CHECKBOX":
                switch(props.name) {
                    case 'SIZE':
                        return <RimSizeSelection
                            values={props.value}
                            options={props.options}
                            containerClasses={"row justify-content-start p-3"}
                            objectClasses={"col-1 m-1"}
                            multiSelect={true}
                            onSelect={props.onChange}
                        />
                    case 'ROLLING_RESISTANCE':
                        return <InputCheckboxGroup {...props}
                                                   euLabelOptions={[
                                                       {
                                                           value: 'A',
                                                           color: '#4BA359',
                                                           width: 1
                                                       }, {
                                                           value: 'B',
                                                           color: '#A3C84B',
                                                           width: 2
                                                       }, {
                                                           value: 'C',
                                                           color: '#E8D027',
                                                           width: 3
                                                       }, {
                                                           value: 'D',
                                                           color: '#F5BA41',
                                                           width: 4
                                                       }, {
                                                           value: 'E',
                                                           color: '#DE3529',
                                                           width: 5
                                                       }
                                                   ]}/>
                    case 'WET_GRIP':
                        return <InputCheckboxGroup {...props}
                                                   euLabelOptions={[
                                                       {
                                                           value: 'A',
                                                           color: '#2564AF',
                                                           width: 1
                                                       }, {
                                                           value: 'B',
                                                           color: '#337EC4',
                                                           width: 2
                                                       }, {
                                                           value: 'C',
                                                           color: '#5CA4D9',
                                                           width: 3
                                                       }, {
                                                           value: 'D',
                                                           color: '#83BCE7',
                                                           width: 4
                                                       }, {
                                                           value: 'E',
                                                           color: '#B3DFFB',
                                                           width: 5
                                                       }
                                                   ]}
                        />
                    case 'NOISE_CLASS':
                        return <InputCheckboxGroup {...props}
                                                   euLabelOptions={[
                                                       {
                                                           value: 'A',
                                                           icon: noiceClassASvg
                                                       },
                                                       {
                                                           value: 'B',
                                                           icon: noiceClassBSvg
                                                       },
                                                       {
                                                           value: 'C',
                                                           icon: noiceClassCSvg
                                                       }
                                                   ]}
                        />
                }
                if (props.options.length === 1) {
                    // SINGLE_CHECKBOX
                    return (
                        <InputCheckbox
                            name={props.name}
                            label={t('FILTER.' + props.name, props.label)}
                            onChange={(e) => onChangeSingleCheckbox(e, props.name)}
                            checked={props.value?.includes(props.name)}
                        />
                    );
                }
                // MULTI_CHECKBOX
                return <InputCheckboxGroup {...props}/>;
            case "RADIO_BUTTON":
                return (
                    <>
                        {props.options?.map((opt, index) => (
                            <div className="form-check" key={props.name + '_' + index} >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={props.name + '_' + index}
                                    id={props.name + index}
                                    value={opt}
                                    onChange={(evt) => props.onChange([evt.currentTarget.value])}
                                    checked={props.value?.includes(opt) || false}
                                />
                                <label className="form-check-label" htmlFor={props.name + '_' + index} onClick={() => props.onChange([opt])}>
                                    {InputHelper.filterLabel(opt, props.name, t, props.translateValue, props.translatePrefix)}
                                </label>
                            </div>
                        ))}
                    </>
                );
            default:
                const { defaultValue, translateValue, formatNumber, onChange, ...cleanProps } = props;
                return <input type="text" onChange={(evt) => onChange([evt.currentTarget.value])} {...cleanProps} />;
        }
    }

    return (
        <FormGroup controlId={props.name}>
            {props.label && (
                <FormLabel
                    className={classNames(props.labelClass, {
                        "label-not-visible": (props.type === "CHECKBOX" && props.options.length === 1) || props.hideLabel,
                    })}
                >
                    {props.label}
                </FormLabel>
            )}
            {getInputByType()}
        </FormGroup>
    );
}
