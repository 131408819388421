import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {Dispatch} from "redux";
import CarSelection from "../../components/CarSelection/CarSelection";
import NotFoundPage from "../../components/layout/NotFoundPage/NotFoundPage";
import { Markdown } from "../../components/Markdown/Markdown";
import { StrapiMetadataHelmet } from "../../components/Strapi/StrapiMetadataHelmet";
import HttpContext from "../../helper/httpContext";
import { StrapiStaticPage } from "../../models/strapi/StrapiStaticPage";
import { getStaticPage } from "../../redux-store/api/StrapiApi";
import {SeoMarketingPageAction, SeoMarketingPageActionType} from "../../redux-store/reducers/seoMarketingPageReducer";
import {CompleteWheels} from "../Home/CompleteWheels/CompleteWheels";
import Info from "../Home/Info/Info";
import RimBrands from "../Home/RimBrands/RimBrands";
import Service from "../Home/Service/Service";
import {useMediaQuery} from "../../helper/useMediaQuery";
import CarSelectionMobile from "../../components/CarSelectionMobile/CarSelectionMobile";
import {scrollToTopFn} from "../../components/ScrollToTop";
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import {useTranslation} from 'react-i18next';

function getStrapiKey(path: string): string {
    let key = path.substring(1).replace(".", "_");
    if (key.endsWith("/")) {
        key = key.slice(0, -1);
    }
    return key
}

function SeoMarketingPage() {
    let params = useParams<{ carBrand?: string }>();
    const carBrandRegex = /\/(?<carBrand>[a-z]+)-komplettraeder\.html/;

    const location = useLocation();
    const dispatch = useDispatch<Dispatch<SeoMarketingPageAction>>();
    const data = useSelector<any, StrapiStaticPage>((s) => s.seoMarketingPage);
    const [errorLoadingContent, setErrorLoadingContent] = useState<boolean>(false);
    const [carBrand, setCarBrand] = useState<string>();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    const {t} = useTranslation();

    useEffect(() => {
        const key = getStrapiKey(location.pathname)
        if (carBrandRegex.test(location.pathname)) {
            const result = carBrandRegex.exec(location.pathname)
            setCarBrand(result.groups['carBrand']);
        }
        if (!data?.attributes || data.attributes.Key !== key) {
            getStaticPage(key).then(
                (page) => dispatch({
                    type: SeoMarketingPageActionType.SET_DATA,
                    payload: page
                }),
                (error) => {
                    dispatch({
                        type: SeoMarketingPageActionType.SET_DATA,
                        payload: undefined
                    })
                    setErrorLoadingContent(true);
                    const httpContext = useContext(HttpContext)
                    httpContext.statusCode = 404
                }
            );
        }
    }, [location.pathname, location]);

    if (errorLoadingContent) {
        return <NotFoundPage></NotFoundPage>
    } else if(!data?.attributes) {
        return <></>
    }

    return (
        <div className="seo-marketing-page-content">
            <Breadcrumb breadcrumbs={[{
                title: t('BREADCRUMBS.SEO', {brandName: carBrand?.charAt(0)?.toUpperCase() + carBrand?.slice(1)})
            }]}/>
            <StrapiMetadataHelmet  metadata={data.attributes.Metadata}/>
            {isSmallScreen
                ? <CarSelectionMobile isRimDetailPage={false} isLandingPage={false} isOnSeoPage={true}
                                      preSelectedManufacturer={carBrand} headline={data.attributes.Headline}
                                      backgroundImage={data.attributes.Metadata?.shareImage?.data?.attributes?.url}/>
                : <CarSelection isRimDetailPage={false} isOnSeoPage={true}
                                preSelectedManufacturer={carBrand} headline={data.attributes.Headline}
                                backgroundImage={data.attributes.Metadata?.shareImage?.data?.attributes?.url}/>
            }
            <div className="seo-wrapper bg-white">
                <div className="container">
                    <Markdown content={data.attributes.Content}></Markdown>
                </div>
            </div>
            <Info/>
            <Service/>
            <RimBrands/>
            <CompleteWheels/>
        </div>
    );
}

SeoMarketingPage.serverFetch = (params, url) => {
    const key = getStrapiKey(url.pathname)
    return getStaticPage(key).then((page) => {
        return [
            {type:SeoMarketingPageActionType.SET_DATA, payload: page}
        ];
    });
};

export default SeoMarketingPage;
